.card-linkwrapper-setup() {
	border-bottom: 1px solid transparent;
	position: relative;
	display: block;
	width: 100%;
	// kvuli stinu, at roztahuje vertikalne parent container:
	// margin na shadow (box-shadow: 0px 2px 8px 0px):
	z-index: 0;
	margin-top: 6px; // 8 - 2
	margin-bottom: 10px; // 8 + 2
	&,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color: @gray-base;
	}
	&:hover,
	&:focus {
		border-bottom: 1px solid @gray;
		color: @brand-blue-dark;
		// kvuli stihnu, at je nad ostatnima:
		position: relative;
		z-index: 1;
		&::after {
			opacity: 1;
			transition: opacity 0.1s ease-in-out;
		}
	}
	&:active {
		border-bottom: 1px solid @gray;
		color: @brand-blue;
		&::after {
			opacity: 0;
		}
	}
	// shadow fast via opacity
	&::after {
		content: '';
		position: absolute;
		pointer-events: none;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
		transition: opacity 0.3s ease-in-out;
	}
}

// Product Card
.pcard {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	&__link-wrapper {
		.card-linkwrapper-setup();
		padding: 1rem;
	}
	&__image {
		position: relative;
		overflow: hidden;
		flex-grow: 0;
		img {
			.img-responsive;
			// povoli zvetseni nad 100% obrazku.
			// vzdy se prizpusobi sirce karty
			width: 100%;
		}
		&--small {
			width: 150px;
			text-align: center;
			margin: auto;
			img {
			}
		}
	}
	&__price {
		font-size: @font-size-large;
		&-beforesale {
			font-weight: @font-weight-semibold;
			// font-weight: @font-weight-regular;
		}
		&-aftersale {
			color: @brand-red;
		}
	}
	&__flags {
		&:extend(.list-unstyled);
		display: flex;
		align-items: flex-end;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-bottom: 0;
	}
	&__flag {
		display: flex;
		align-content: center;
		justify-content: center;
		line-height: 1rem;
		padding: @space-xs 0;
		flex-basis: 50%;
		color: white;
		text-transform: uppercase;
		font-weight: @font-weight-bold;
		font-size: @font-size-smalleer;
		white-space: nowrap;
		text-align: center;
		&--isback {
			font-size: @font-size-smallest;
			background: @brand-green;
		}
		&--out_of_stock {
			font-size: @font-size-smallest;
			color: @brand-pink--sat;
			background: @gray-lighteer;
		}
		&--action {
			background: @brand-blue;
		}
		&--discount {
			background: @brand-red;
			margin-left: auto;
			flex-direction: column;
			flex-wrap: nowrap;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 100%;
				right: 0;
				// background: @brand-red;
				// width: 0;
				// height: 0;
				border-style: solid;
				border-width: 0 0 40px 300px;
				border-color: transparent transparent @brand-red transparent;
			}
			strong {
				font-size: 2em;
				font-weight: @font-weight-bold;
				letter-spacing: -0.035em;
				display: block;
			}
			span {
				display: block;
			}
		}
	}
	&__sticker {
		position: absolute;
		&--bio {
			right: 10px;
			top: 10px;
			display: block;
			width: 80px;
		}
		&--sustainable {
			right: 10px;
			top: 10px;
			display: block;
			width: 60px;
			img {
				width: 60px;
				height: auto;
			}
		}
	}
	&__icons {
		position: absolute;
		left: 10px;
		top: 10px;
		.card-icon {
			margin-bottom: 0.5rem;
			&--favourite {
				background-color: @brand-red;
				border-radius: 50%;
				width: 1.5rem;
				height: 1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
				font-size: 0.9em;
			}
		}
	}
	&__title {
		padding: @space-lg 0 @space-sm;
		text-align: center;
		flex-grow: 2;
	}
	&__meta {
		padding: 0 0 @space-sm;
		text-align: center;
		flex-grow: 0;
		font-size: 0.875rem;
		color: gray;
	}
	&__name {
		font-size: @font-size-base;
		text-transform: none;
		margin: 0;
		@media(max-width:@screen-xs-max){
			font-size: 1.25rem;
		}
	}
	&__footer {
		text-align: center;
		flex-grow: 0;
	}
}

.card--default {
	background: white;
	padding: @space-lg;
	margin-bottom: @space-sm;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;

	// pokud ma 'card' wrapper, tak dame margin na wrapper a margin na 'card' zrusime
	.card__wrapper & {
		margin: 0;
	}
	.card {
		&__image,
		&__title {
			font-size: @font-size-base;
			font-weight: @font-weight-bold;
			text-transform: none;
			text-align: center;
		}
		&__image {
			.space-stack-base;
			.icon {
				font-size: @font-size-h2;
				color: @brand-blue;
			}
		}
		&__title {
			font-size: 1.25rem;
			margin: 0;
			min-height: 3em;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

// pokud je odkaz obalem cele karty
.card__link.card__wrapper {
	.card-linkwrapper-setup();
	.space-stack-xl;
	&[href="/"] {
		opacity: 0.4;
	}
}

.card--horizontal {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	.card__actions {
		margin-left: auto;
		flex-basis: 0;
		white-space: nowrap;
	}
}

.card--active {
	border: 1px solid @brand-blue;
}

.card__actions {
	a {
		// color: inherit;
	}
}
.card__action {
	&:extend(.btn, .btn--ghost);
	color: @gray-dark;
	&:hover, &:focus {
		color: @gray-darker;
	}
	&:active {
		color: @brand-blue;
	}
	.icon {
		font-size: 1.25rem;
	}
	
}

// Carty 

.card--article {
	padding: 0;
	padding-bottom: @space-lg;
	margin-bottom: -1px;
	border-bottom: 1px solid @gray-lighter;

	.card {
		&__image {
			.space-stack-xl;
		}
		&__title {
			font-weight: @font-weight-bold;
			text-transform: none;
			font-size: @font-size-large;
			.space-stack-sm;
			line-height: 1.3;
		}
		&__teaser {
			.space-stack-base;
			font-size: @font-size-small;
			line-height: 1.25;
		}
		&__footer {
			display: flex;
			flex-flow: row nowrap;
			font-weight: @font-weight-semibold;
			.list--tags {
				flex-grow: 2;
				color: @brand-blue;
			}
		}
		&__readmore {
			color: @brand-danger;
			display: flex;
			width: 100%;
			justify-content: space-between;
			> div {
			}
			.icon {
				margin-left: 0.25rem;
			}
		}
	}
}

.card__link.card__link--article.card__wrapper {
	padding: @space-base;
	padding-bottom: 0;
	&:hover,
	&:active,
	&:focus {
		.card {
			border-bottom: 1px solid transparent
		}
	}
}

.card__wrapper--mixer {
	.card-linkwrapper-setup();
	display: inline-block;
	width: auto;
	margin: 0;
}

.card--mixer {
	&:hover,
	&:active,
	&:focus {

	}
	.btn {
		.space-inset-sm;
		color: @gray-base;
		font-weight: @font-weight-semibold;
		&:hover,
		&:active,
		&:focus {
			color: @brand-danger;
		}
	}
	.card__image {
		
		img {
			visibility: hidden;
		}
	}
	.card__footer {
		background: white;
		transition: opacity 0.1s ease-in-out;
		opacity: 0;
		display: flex;
		> .btn {
			flex: 1 0 auto;
		}
	}
}

.card--mixer:hover {
	.ui-resizable-handle {
		&.ui-resizable-e {

		}
	}
	.card__footer {
		opacity: 1;
		transition: opacity 0.1s ease-in-out;
	}
	.ui-icon-gripsmall-diagonal-se {
		display: block;
	}
}

.card--mixer .ui-resizable-resizing {
	.ui-resizable-handle {
		&.ui-resizable-e {

		}
	}
	+ .card__footer {
		opacity: 1;
		transition: opacity 0.1s ease-in-out;
	}
	.ui-icon-gripsmall-diagonal-se {
		display: block;
	}
}

.list--products .list__item {
	.fav_status {
		display: none !important;
	}
}
