//
// Close icons
// --------------------------------------------------


.close {
	.opacity(1);

	&:hover,
	&:focus {
		color: @brand-danger;
	.opacity(1);
	}
}
