.timeline {
	counter-reset: stepNumber;
	overflow: hidden;
	border-bottom: 1px solid @gray-lighter;
	margin: 0 @grid-gutter-width/-2;
	margin-bottom: @space-base;
	@media (min-width: @screen-md-min) {
		margin: 0;
		margin-bottom: @space-base;
	}
	&:extend(.list-unstyled);

	display: flex;
	a {
		&, &:link, &:visited {
			text-decoration: none;
			color: @gray-dark;
		}
		&:hover, &:active, &:focus{
			text-decoration: none;
			color: @gray-darker;
		}
	}
}

.timeline__item {
	counter-increment: section;
	// flex-grow: 1;
	color: @gray-dark;
	flex-basis: 25%;
	padding: @space-base @space-lg;
	padding-right: @space-xs;
	@media (min-width: @screen-md-min) {
		padding: @space-base;
	}
	line-height: 1.1;
	background: @gray-lighteer;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: @font-weight-bold;
	position: relative;
	@media (min-width: @screen-md-min) {
		font-size: 1.25rem;
	}
	&.active {
		background: @gray-lighteer;
		color: @gray-base;
		&::after {
			border-color: transparent transparent transparent @gray-lighteer;
		}
	}
	&.disabled {
		background: @gray-lighter;
		&::before {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
			content: "";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 2em 0 2em 1em;
			border-color: transparent transparent transparent @gray-lighteer;
		}
		&::after {
			border-color: transparent transparent transparent @gray-lighter;
			// border-color: transparent transparent transparent @brand-danger;
			left: calc(~"100% - 3px");
		}
	}
	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		&::after {
			content: none;
		}
	}
	> a {
		height: 100%;
		&:link, &:visited, &:hover, &:active, &:focus{
			text-decoration: none;
		}
	}
	&::after {
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translateY(-50%);
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 2em 0 2em 1em;
		border-color: transparent transparent transparent @gray-lighteer;
	}
}

.timeline__item-title {
	font-weight: @font-weight-light;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	@media (min-width: @screen-md-min) {
		display: block;
	}

	&::before {
		min-width: 2em;
		display: inline-block;
	}
	.timeline__item.disabled &,
	.timeline__item.active & {
		&::before {
			content: counter(section)".";
			font-weight: @font-weight-black;
		}
	}
	.timeline__item:not(.disabled):not(.active) & {
		&::before {
			content: "\f122";
			font-family: @font-family-icons;
			font-size: @font-size-larger;
			color: @gray-light;
			line-height: 1rem;
			transform: translateY(-0.15em);
			@media (min-width: @screen-md-min) {
				transform: none;
			}
		}
	}
}

.timeline__item-title-txt {
	display: flex;
	align-items: center;
	// border: 1px solid lime;
	flex-grow: 2;
	@media (min-width: @screen-md-min) {
		display: inline-block;
	}
}



//START generuji

.generate-wizard-arrow-zindex(4);

.generate-wizard-arrow-zindex(@n, @i: 1) when (@i =< @n) {
	.timeline__item:nth-child(@{i}){
		position: relative;
		z-index: @n - @i + 1;
	}
	.generate-wizard-arrow-zindex(@n, (@i + 1));
}

//END generuji