
// M$ IE
body { 
	scrollbar-arrow-color: @brand-blue;
	scrollbar-base-color: @gray-lighteer;
	scrollbar-face-color: @brand-blue;
	scrollbar-shadow-color: @gray-lighteer;
}

// webkit
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: @brand-blue;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: @brand-blue;
}
::-webkit-scrollbar-thumb:active {
  background: @brand-blue;
}
::-webkit-scrollbar-track {
  background: @gray-lighteer;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: @gray-lighteer;
}
::-webkit-scrollbar-track:active {
  background: @gray-lighteer;
}
::-webkit-scrollbar-corner {
  background: transparent;
}