.delivery_service_branch {
	display: none;
	padding: @space-base @space-lg;
	// margin-left: 2* @space-xl;
	padding-left: 2* @space-xl;
	background: white;
	margin-top: @space-xs;
	.branch_button {
		margin-left: auto;
	}
	.branch_address {
		font-weight: @font-weight-bold;
		color: @gray-base;
	}
}

#form_checkouts_set_payment_and_delivery_method ul.list--radios li.checked .delivery_service_branch {
	display: flex;
}
