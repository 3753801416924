// Yarriho tuning stylu pro tisk

@media print {
	body {
		max-width: 100%;
		* {
			overflow: visible;
		}
	}
	.header-main {
		//display: none;
		.section--navmain, .section--preheader {
			display: none;
		}
		.section--header {
			.hamburger, .userinfo {
				display: none;
			}
		}
	}

	.footer {
		min-height: 0;

		.section--footer {
			border-bottom: none;
		}
	}
	
	.section--feedback-form, .wholesaling__forms, .section--newslettersubs, .job-body-gallery,  .job__gallery, .stores-index-locator .smap {
		display: none !important;
		height: 0 !important;
	}
	
	h1 {
		font-size: 2rem !important;
	}
	
	.list--contacts {
		position: relative;
		.list__item {
			position: relative;
			page-break-inside: avoid;
			break-inside: avoid;
			display: flex !important;
			.contact-thumbnail {
				max-width: 20%;
				padding-right: 0.5cm !important;
			}
			.contact-buttons {
				display: none !important;
			}
		}
	}
	
	.store-detail-title {
		display: block;
		.store-pager {
			display: none;
		}
	}
	
	.icon, .pictogram {
		print-color-adjust: exact;
		-webkit-print-color-adjust: exact;
	}
	
	// obrazkovy border kuponu pryc
	.coupon-wrap {
		border-image-width: 0;
		border-image-source: none;
		background-color: transparent;
	}
	
	// sidebar u clanku pryc
	.body_articles_detail, .body_articles_index {
		.layout__col--aside {
			display: none;
		}
	} 
	
	article.store-detail .store-detail-info .store-hours {
		table.table { 
			//border: 4px solid blue;
			page-break-inside: auto;
			break-inside: auto;
			page-break-before: auto;
			break-before: auto;
			tbody tr {
				page-break-inside: auto;
				break-inside: auto;
				page-break-before: auto;
				break-before: auto;
				td, th {
					font-size: 1rem !important;
				}
			}
    }
	}
	.store-detail-services {
		.service-icon {
			display: none !important;
		}
		ul {
			li {
				font-size: 1rem !important;
				line-height: 1.1 !important;
				text-align: left !important;
			}
		}
	}
	.store-detail-gallery {
		display: none;
	}

	
	// dulezite, bez tohohle se stranka pri tisku z firefoxu totalne rozpadne
	.page__drawer, .page__base {
		position: static !important;
		display: block !important;
		transform: none !important;
		width: auto !important;
	}

}
