.product-promotions {
	margin-bottom: -2rem;
	min-height: 60px;
	.product-promotions-item {
		//border: 1px dotted fade(black,20%);
		
		.container {
			display: flex;
		}
		&:nth-child(2n+1){
			background-color: @gray-lighteer;
		}
		&:nth-child(2n+2){
			background-color: white;
			.section__surface--lace;
			&:after {
				z-index: 0;
			}
			&:before {
				display: none;
			}
			.product-promotions-item-text {
				order: 2;
				padding-bottom: 3rem;
			}
			.product-promotions-item-image {
				order: 1;
			}
		}
		&:first-child {
			background-image: url(../images/bg-torn.jpg);
			background-position: top center;
			background-repeat: repeat-x;
			padding-top: 1.5rem;
		}
		&.bg-wide {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-color: transparent;
			&:before, &:after {
				display: none;
			}
			box-shadow: 0 4px 2px 0 rgba(0,0,0,.05);
			margin-bottom: 0;
			@media(max-width:@screen-xs-max){
				background-image: none !important;
			}
			.product-promotions-item-text {
				padding-bottom: 4rem;
			}
			@media(min-width:@screen-sm-min){
				.product-promotions-item-image {
					background-image: none !important;
				}
			}
		}
		.product-promotions-item-text {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-top: 5rem;
			padding-bottom: 4rem;
			flex-basis: 50%;
			h3 {
				font-weight: 700;
				color: @brand-blue-dark;
				font-size: 2.75rem;
				margin-bottom: 2rem;
				small {
					font-size: 1em;
					color: inherit;
				}
			}
			p {
				margin-bottom: 2rem;
			}
			.product-promotions-logo {
				margin-bottom: 2rem;
			}
			
			.product_promotions-item__link {
				@media( min-width: @screen-md-min ){
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					//justify-content: space-between;
				}
				.btn {
					margin-right: 2em;
				}
				.link-image {
					@media(min-width:600px) {
						display: inline-block;
						//margin-left: 2em;
						img {
							width: 200px;
							height: auto;
						} 
					}
					@media(max-width:@screen-sm-max)and(min-width:@screen-sm-min) {
						display: inline-block;
						margin-top: 1em;
						img {
							width: 200px;
							height: auto;
						} 
					}
					@media(max-width:599px) {
						display: inline-block;
						margin-top: 1em;
						img {
							width: 200px;
							height: auto;
						} 
					}
				}
			}
			
		}
		.product-promotions-item-image {
			background-position: center center;
			background-size: contain;
			background-repeat: no-repeat;
			flex-basis: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			img {
				//border: 1px dotted fade(red,10%);;
				height: auto;
				max-height: 900px;
				width: auto;
    		max-width: 350px;
			}
			.image-move-up {
				position: absolute;
				width: 100%;
				//height: calc(100% + 20px);
				height: 100%;
				transform: translateY(-30px);
				left: 0;
				bottom: 0;
				//border: 1px solid red;
				//background-color: red;
				background-position: center center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
		
		.product-promotions-item-image-wider {
			img {
				width: 100%;
				max-width: 550px;
				height: auto;
			}
		}
		
		&.image_bottom {
			.product-promotions-item-image {
				justify-content: flex-end;
			}
		}
		
		@media(max-width:@screen-xs-max){
			.container {
				display: block;
			}
			.product-promotions-item-image {
				margin-left: -1.25rem;
				margin-right: -1.25rem;
				min-height: 300px;
				img {
					max-height: 100vh;
					max-width: 100%;
					margin-bottom: 1rem;
					margin-bottom: 1rem;
				}
				.image-move-up {
					transform: none;
				}
			}
			&.bg-wide {
				.product-promotions-item-image {
					background-size: cover;
				}
				&:nth-child(2n+1){
					.product-promotions-item-image {
						background-position: top right;
					}
				}
				&:nth-child(2n+2){
					.product-promotions-item-image {
						background-position: top left;
					}
				}
			}
		}
	}
}



body.body_pages_detail[data-page-code="style_about"] {
	.article {
		&__teaser {
			h2 {
				font-weight: bold;
				margin-bottom: 0;
				
			}
		}
	}
	.product-promotions {
		margin-top: 3rem;
		&-item {
			//border: 1px dotted red;
			// vyresetovat dekorace z product promotions
			background-color: transparent !important;
			background-image: none;
			box-shadow: none !important;
			//background-color: fade(fuchsia,20%) !important;
			//background-color: fade(white,70%) !important;
			margin-top: 0;
			margin-bottom: 0;
			&:after {
				//border: 1px dashed green;
				background-image: none !important;
				content: none;
				display: none;
			}
			.container {
				position: relative;
				&:before, &:after {
					position: absolute;
					content: "";
					left: 0;
					bottom: -5rem;
					width: 100%;
					height: 10rem;
					//background-color: fade(goldenrod, 10%);
					//border: 1px dashed blue;
					z-index: 0;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
				&:after {
					top: -5rem;
					//border-color: violet;
					display: none;
				}
			}
			&-text {
				padding-top: 3rem;
				padding-bottom: 1rem;
				z-index: 20;
				h3 {
					font-size: 2.25rem;
				}
			}
			&-image {
				//background-color: fade(yellowgreen, 10%);
				z-index: 20;
				margin: -20px 0 -20px 0;
			}
			
			&:nth-child(1) {
				.container {
					&:before {
						background-image: url(../images/onas-curve2.svg);
					}
					&:after {
						background-image: url(../images/onas-curve1.svg);
						display: block;
					}
				}
			}
			&:nth-child(2) {
				.container {
					&:before {
						background-image: url(../images/onas-curve3.svg);
					}
				}
			}
			&:nth-child(3) {
				.container {
					&:before {
						background-image: url(../images/onas-curve4.svg);
					}
				}
			}
			&:nth-child(4) {
				.container {
					&:before {
						background-image: url(../images/onas-curve5.svg);
					}
				}
			}
			&:nth-child(5) {
				.container {
					&:before {
						background-image: url(../images/onas-curve6.svg);
						height: 340px;
						bottom: -280px;
					}
				}
			}
			&:nth-child(6) {
				.container {
					&:before {
						display: none;
					}
					.product-promotions-item-image {
						display: none;
					}
					.product-promotions-item-text {
						padding-top: 280px;
						text-align: center;
						flex-basis: 100%;
					}
				}
			}
			
		}
	}
	@media(max-width:@screen-xs-max){
		.product-promotions-item {
			.container {
				//padding-top: 3rem;
				padding-bottom: 2rem;
				.product-promotions-item-text {
					padding-top: 0;
					padding-bottom: 0;
				}
				.product-promotions-item-image {
					//margin: 0;
					margin-left: 0 - @grid-gutter-width/2;
					margin-right: 0 - @grid-gutter-width/2;
				}
			}
			&:nth-child(5) {
				.container {
					&:before {
						height: 180px;
						bottom: -120px;
					}
				}
			}
			&:nth-child(6) {
				.container {
					.product-promotions-item-text {
						padding-top: 130px;
					}
				}
			}
		}
	}
}