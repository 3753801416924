.icon-lg, .icon--lg {
	font-size: 1.5rem;
	vertical-align: middle;
}

.icon-xl, .icon--xl {
	font-size: 2.25rem;
	vertical-align: middle;
}

.icon-xxl, .icon--xxl {
	font-size: 3rem;
	vertical-align: middle;
	&::before {
		vertical-align: top;
	}
}

.icon--dark {
	color: @gray-darker;
	&:hover {
		color: @btn-danger-bg-hover;
	}
}

// pokud je ikona soucasti odkazu, nikdy ji nepodtrhavej
a,
a:link,
a:hover,
a:visited,
a:active,
a:focus {
	.icon {
		&,
		&::before,
		&::after {
			text-decoration: none !important;
		}
	}
}