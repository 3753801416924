.pswp__bg {
	background: rgba(0, 0, 0, 0.9);
}
.pswp__ui--fit {
	.pswp__caption, .pswp__top-bar {
		background: none;
	}
}
.pswp__caption__center {
	text-align: center;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
	width: 10rem;
	height: 10rem;
	font-size: 4rem;
	&:hover,
	&:focus {
		background: rgba(255, 255, 255, 0.3);
		background: rgba(0, 0, 0, 0.3);
	}
	&::before {
		background: none;
		background-image: none !important;
		font-family: @font-family-icons;
		color: white;
		position: static;
		height: auto;
		width: auto;
	}
}
.pswp__button--arrow--left {
	&::before {
		content: "\f3d5"
	}
}
.pswp__button--arrow--right {
	&::before {
		content: "\f3d6"
	}
}

.pswp__img {
	border: 1rem solid white;
}