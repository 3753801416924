.pager-buttons {
	text-align: center;
	.btn.disabled {
		display: none;
	}
}

.pagination {
	display: block;
	margin: 0 auto;
	text-align: center;
	ul {
		&:extend(.list--inline all);
		font-size: @font-size-large;
		> li {
			font-weight: @font-weight-semibold;
			&.active {
				color: @brand-danger;
			}
			&.active, a {
				.space-inset-base;
			}
			> a {
				color: @gray-base;
			}
		}
	}
}
