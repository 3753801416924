// fitracni checkboxy v kategoriicich
.checkboxes {
	.disabled {
		cursor: not-allowed;
		color: lightgray;
	}
	.checkbox {
		&:hover,
		&:focus,
		&:focus-within {
			color: @brand-blue;
			cursor: pointer;
		}
		&:active {
			color: @brand-danger;
		}
		&--checked {
			color: @brand-blue;
			&:hover,
			&:focus,
			&:focus-within {
				color: @brand-blue-dark;
			}
		}
	}
}

label {
	cursor: pointer;
}

input[type="number"] {
	padding: 0.25em;
	text-align: center;
}

legend, .form__legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: @line-height-computed;
  font-size: (@font-size-base * 1.5);
  line-height: inherit;
  color: @legend-color;
  border: 0;
  border-bottom: 1px solid @legend-border-color;
}

.form-control {
	box-shadow: none !important;
	&:focus,
	&:active {
		background: white;
	}
	&:focus {
		border: 1px solid @input-border-focus;
	}
	&:active {
		border: 1px solid @gray-base;
	}
}

.form__body {
	.space-stack-base;
}

.form-group-required {
	> .control-label {
		&::after {
			content: "*";
			color: @brand-danger;
			font-weight: @font-weight-bold;
			display: inline-block;
			padding-left: 0.25em;
		}
	}
}

.input-group {
	.form-control {
		// buttons height
		height: 3.125rem;
	}
}
.input-group-lg {
	.form-control {
		height: 4.15625rem;
	}
	> .input-group-btn > .btn {
		height: auto;
		&--cta {
			padding-left: 2 * @padding-base-horizontal;
			padding-right: 2 * @padding-base-horizontal;
		}
	}
}

// imnout voucheru v kosiku
.input-group--voucher {
	@media (min-width: @screen-md-min) {
		width: 20rem;
	}
}

select.form-control {
	padding-top: @space-sm;
	padding-bottom: @space-sm;
	padding-right: 2rem;
	font-weight: @font-weight-semibold;
	background-image: url("../images/icon--caret.png"); 
	background-repeat: no-repeat;
	background-size: 16px 16px;
	background-position: right 0.5rem center;
	background-color: white;
	// background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	&::-ms-expand {
		display: none;
	}
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
	text-indent: 0.01px;
	text-overflow: "";
}

ul.radios {
	&:extend(.list-unstyled);
	display: flex;
	flex-flow: row wrap;
	> li {
		margin-right: @space-xxl;
	}
	label {
		margin: 0;
	}
	input {
		margin: 0;
		display: inline-block;
		vertical-align: middle;
	}
}

//noUiSlider

.noui-slider__wrapper {
	// padding tu musi byt jinak kolapsuje margin 'noUi-target'
	padding: @space-sm 0 1px;
}

.noUi-horizontal {
	height: 0.25rem;
}

.noUi-target {
	border-radius: 0;
	margin: 0.5em 1em 2.5em;
	box-shadow: none;
	border: none;
	background: @gray-light;
}

.noUi-horizontal .noUi-tooltip {
	bottom: auto;
	top: 100%;
	background: @brand-blue;
	color: white;
	font-weight: @font-weight-semibold;
	border: none;
	border-radius: 0;
	line-height: 1;
	font-size: @font-size-smaller;
	padding: 0.5em;
	&::after {
		content: "";
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(213, 0, 0, 0);
		border-bottom-color: @brand-blue;
		border-width: 0.4em;
		margin-left: -0.4em;
	}
}

.noUi-connect {
	background: @brand-blue;
}

.noUi-active {
}

.noUi-handle {
	outline: none;
	background: none;
	border: none;
	&::before {
		@size: 0.9em;
		content: "";
		width: @size;
		height: @size;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		background: @brand-blue;
		border-radius: 50%;
	}
	&::after {
		content: none;
	}
}

.noUi-origin {
	top: 50%;
}

.noUi-horizontal .noUi-handle {
	border-radius: 0;
	box-shadow: none;
	transform: translateY(-50%);
	top: 50%;
}

.noui-slider--disabled {
	.noUi-connect,
	.noUi-handle::before,
	.noUi-tooltip {
		background: @gray-light;
	}
	.noUi-tooltip::after {
		border-bottom-color: @gray-light;
	}
}




.section--content form:not( "#add_to_basket_form" ) {
	background: white;
	padding: @space-xl @space-xl;
	border-bottom: 1px solid @gray-lighter;
	.form-control {
		&:extend(.input-sm all);
	}
}

.cards_paging_form,
#filter_form {
	padding: 0;
	.form__body {
		margin: 0;
	}
}

#filter_form {
	.control-label {
		font-weight: @font-weight-semibold;
	}
}

body[data-controller="delivery_addresses"] .section--content form,
body[data-controller="users"] .section--content form,
body[data-controller="checkouts"] .section--content #form_checkouts_set_billing_and_delivery_data {
	background: white;
	padding: @space-lg;
	margin-bottom: @space-xl;
	.form__body {
		@media (min-width: @screen-md-min) {
			max-width: calc(2/3 * @container-large-desktop);
			display: flex;
			flex-flow: row wrap;
		}
		.form-group {
			@media (min-width: @screen-md-min) {
				flex-basis: calc(~"100% * 1/2");
				padding-right: @space-xl;
			}
		}
		.form-group--id_company,
		.form-group--id_delivery_company,
		.form-group--id_gender_id_0 {
			flex-basis: 100%;
		}
		.form-group--id_firstname,
		.form-group--id_email,
		.form-group--id_company_number,
		.form-group--id_address_street,
		.form-group--id_address_city,
		.form-group--id_address_country,
		.form-group--id_phone,
		.form-group--id_password,
		.form-group--id_password_repeat,
		.form-group--id_sign_up_for_newsletter,
		.form-group--id_company,
		.form-group--id_delivery_firstname,
		.form-group--id_delivery_email,
		.form-group--id_delivery_company_number,
		.form-group--id_delivery_address_street,
		.form-group--id_delivery_address_city,
		.form-group--id_delivery_address_country,
		.form-group--id_delivery_phone,
		.form-group--id_delivery_password,
		.form-group--id_delivery_password_repeat,
		.form-group--id_delivery_sign_up_for_newsletter,
		.form-group--id_delivery_company {
			// tohle vynucene zalamovani funguje bohuzel pouze v FF
			// break-before: always; /* New syntax */
			// page-break-before: always; /* CSS 2.1 syntax */
		}
		.form-group--id_email,
		.form-group--id_address_country,
		.form-group--id_phone_mobile,
		.form-group--id_delivery__email,
		.form-group--id_delivery__address_country,
		.form-group--id_delivery__phone_mobile {
			margin-bottom: @space-xl;
		}
		.form-group:last-child {
			margin-bottom: 0;
		}
	}
	.form__footer {
		padding: @space-base 0;
	}
}

body[data-controller="users"] .section--content form {
	// Yarri: toto vynuti zalomeni toku policek v registracnim formulari http://dumlatek.localhost/cs/users/create_new/
	// Za polickem "Telefon" je zalomeni a policka "Heslo" a "Heslo (opakovani)" jsou tak na svem samostatnem radku
	.form-group--id_phone {
		margin-right: 0.1px;
	}
}

// formular bez pozadi
[data-controller="baskets"] .section--content #form_baskets_edit,
[data-controller="checkouts"] .section--content #form_checkouts_set_billing_and_delivery_data,
[data-controller="checkouts"] .section--content #form_checkouts_summary,
[data-controller="checkouts"] .section--content #form_checkouts_set_payment_and_delivery_method {
	padding: 0;
	background: none;
	border-bottom: none;
	.form__body {
		border-bottom: 1px solid @gray-lighter;
		padding: 0;
	}
	.form__footer {
		display: flex;
		justify-content: flex-end;
		padding: @space-base 0;
		align-items: center;
		// kvuli bugu v IE 11 https://stackoverflow.com/questions/37534254/flex-auto-margin-not-working-in-ie10-11/37535548#37535548
		// naseldujici kod vidi jen IE
		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
			justify-content: inherit;
		}
		.btn--back {
			margin-right: auto;
		}
	}
	.disabled {
		color: gray;
		cursor: not-allowed; 
	}
}

// form kde je i form-footer na bilem pozadi, je potreba oddelit sedou carou
// jsou to asi ty formualre kde je i navigace zpet.
[data-controller="checkouts"] .section--content #form_checkouts_set_billing_and_delivery_data {
	.form__body {
		border-bottom: none;
		// background: white;
		// padding: @space-lg;
		margin-bottom: @space-xl;
	}
	.form__footer {
		border-top: 1px solid @gray-lighter;
	}
}

[data-controller="checkouts"] .section--content #form_checkouts_set_payment_and_delivery_method {
	.form-group--id_payment_method_id_0,
	.form-group--id_delivery_method_id_0 {
		margin: 0;
	}
	.list--radios {
		margin: 0;
		display: flex;
		flex-flow: column nowrap;
		.space-stack-lg;
		> .list__item {
			margin: 0;
			margin-bottom: @space-sm;
		}
		.radio {
			// no disabled neni hover effect
			&:not(.radio--disabled) {
				.card-linkwrapper-setup();
			}
			position: relative;
			background: white;
			margin: 0 !important;
			transition: opacity 0.2s linear;
			&--disabled {
				transition: opacity 0.2s linear;
				filter: grayscale(100%);
				opacity: 0.5;
			}
			> input {
				position: absolute;
				left: @space-lg;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
				display: block;
			}
			> label {
				padding: @space-lg;
				padding-left: 2 * @space-xl;
				display: flex;
				align-items: center;
			}
			img {
				display: block;
				height: 4.5rem;
				width: auto;
				margin: -@space-lg @space-base;
				// margin-left: -@space-sm;
				margin-left: 0;
				
			}
			.v-hint {
				.sr-only;
			}
			.v-price,
			.v-name {
				font-size: @font-size-large;
				font-weight: @font-weight-bold;
			}
			.v-price {
				margin-left: auto;
				&.for-free {
					color: @brand-green;
				}
			}
		}
	}
	// loga platebnich karet
	// TODO: co kdyz se zmani ID platby? Ikony pak budou u spatneho typu. Idelane predela adminsitraci na upllad obrazku k platbe.
	//	label[for="id_payment_method_id_1"] {
	//		.v-name {
	//			&::after {
	//				content: "";
	//				display: inline-block;
	//				height: 3rem;
	//				margin: -1.5rem 0; // zabira 0 vysku
	//				width: 9rem;
	//				background-image: url("../images/icon-payments.png");
	//				background-size: contain;
	//				background-repeat: no-repeat;
	//				background-position: center center;
	//				vertical-align: middle;
	//				margin-left: @space-sm;
	//			}
	//		}
	//	}
}

.form__footer .form__confirmation {
	
	font-size: 0.9375rem;
	color: @gray-darker;
	text-align: right;
	padding-right: @space-xl;
}

// cards/detail hodnoceni
#form_comments_create_new {
	.form__body {
		display: flex;
		flex-flow: row wrap;
		> .form-group {
			flex-basis: 50%;
			&:last-child {
				margin: 0;
			}
		}
		> .form-group.form-group--id_body {
			flex-basis: 100%;
		}
		#id_body {
			height: 6rem;
		}
	}
	.form__footer {
		text-align: center;
		margin-bottom: -3.5em;
	}
}

// v kosiku je formular posunut trochu nahoru (interakce s page_title)

[data-controller="baskets"] .section--content #form_baskets_edit {
	@media (min-width: @screen-md-min) {
		margin-top: -2.5rem;
	}
}

//

.cards_paging_form {
	border: none;
	.space-stack-base;
	.form__body {
		display: flex;
		.form-group {
			display: flex;
			align-items: center;
			margin: 0;
			// flex-grow: 0;
			label {
				font-weight: @font-weight-light;
				font-size: @font-size-small;
				margin: 0;
				white-space: nowrap;
				margin-right: @space-base;
			}
			&:last-child {
				// margin-left: auto;
			}
			&.catproductcount {
				margin: 0 auto;
				// roztahne se na celou sirku
				// flex-basis: 100%;
				// order: 1;
				flex-shrink: 1;

				// preteceni s "..."
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				// flex-grow: 1;
				span {
					font-weight: @font-weight-light;
				}
			}
		}
	}
	.form__footer {
		display: none;
	}
	.control-label {
		display: none;
		@media (min-width: @screen-sm-min) {
			display: block;
		}
	}
}

[data-controller="checkouts"] #form_checkouts_summary {
	// Souhlasím se zasíláním informací o novinkách, ...
	.form-group--id_gdpr {
		max-width: 400px;
		text-align: center;
		@media (min-width: @screen-md-min) {
			text-align: right;
		}
	}

	// 'Souhlasím s obchodními podmínkami' doprava
	.form-group--id_confirmation {
		text-align: center;
		margin: 0;
		@media (min-width: @screen-md-min) {
			text-align: right;
		}
		font-size: @font-size-large;
		label {
			// color: @brand-success;
			font-weight: @font-weight-bold;

		}
		&.has-error {
			label {
				color: inherit;
				font-weight: @font-weight-bold;
			}
			.help-block {
				font-size: @font-size-base;
			}
		}
	}
}


// Stylovani Custom input type nuber format spinners

@numberHeight: 2.6rem;

.table-products__amount {
	white-space: nowrap;
}
.number-style {
	display: inline-block;
	white-space: nowrap;
}

// Stepper.js
.js-spinner {
	&:focus-within {
		.form-control {
			background: white;
			border: 1px solid @brand-blue;
		}
	}
	button {
		margin: 0 0.25rem;
		min-width: 1.9em;
		text-align: center;
		padding: @space-sm;
		height: @numberHeight;
		cursor: pointer;
		display: inline-block;
		border: 1px solid @gray-light;
		background: @gray-lightest;
		vertical-align: middle;
		&:hover,
		&:focus,
		&:active {
			background: white;
			border: 1px solid @brand-blue;
		}
		&:active {
			background: @gray-lighter;
		}
	}
}

.section--content form .form-control.order-quantity-input {
	height: @numberHeight;
	vertical-align: middle;
	// remove spinners in input
	// firefox
	-moz-appearance:textfield;
	// Webkit browsers like Safari and Chrome
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}


.section--feedback-form {
	margin-bottom: 4rem;
	h3, .h3 {
		text-align: center;
		font-size: 2.5rem;
		text-transform: none;
		font-weight: 100;
		margin-bottom: 2rem;
	}
}


form.feedback-form {
	.form-row {
		.col {
			padding: 0;
		}
		@media(min-width:@screen-sm-min){
			display: flex;
			justify-content: space-between;
			max-width: 800px;
			margin: auto;
			//margin-bottom: 2rem;
			.col {
				flex-basis: calc(50% - 20px);
				padding: 20px;
			}
		}
	}
	.help-block {
		font-size: 0.875rem; color: @gray;
	}
	.form-footer{
		text-align: center;
	}
}