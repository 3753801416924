// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border; @background_hover; @background_focus) {
	color: @color;
	background-color: @background;
	border-width: 1px;
	border-color: @background;
	border-style: solid;

	&:focus,
	&.focus {
		color: @color;
		background-color: @background_focus;
		border-color: @background_focus;
	}
	&:hover {
		color: @color;
		background-color: @background_hover;
		border-color: @background_hover;
		
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @color;
		background-color: @background_focus;
		border-color: @background_focus;
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
		background-color: @background_focus;
		border-color: @background_focus;
		}
	}

	.badge {
		color: @background;
		background-color: @color;
		border-color: @color;
	}
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
	padding: @padding-vertical @padding-horizontal;
	font-size: @font-size;
	line-height: @line-height;
	border-radius: @border-radius;
}
