.swiper-outer {
	position: relative;
	
	.list--products {
		margin: 0;
		align-items: stretch;
		.list__item {
			margin-bottom: 0;
			height: auto;
		}
	}

	@nav-btn-width: 3rem;
	
	.swiper-button {
		font-size: 16px;
		position: absolute;
		z-index: 10;
		top: 0;
		bottom: 0;
		width: @nav-btn-width;
		&::after {
			position: absolute;
			line-height: 1;
			width: @nav-btn-width;
			padding: 0 0.25rem;
			height: 1.5rem;
			content: "";
			left: 50%;
			top: 7.5rem;;
			font-family: @font-family-icons;
			font-size: 1.5rem;
			transform: translate(-50%,-50%);
		}
		&--prev {
			left: -@nav-btn-width;
			text-align: right;
			&::after {
				content: "\f124";
			}
		}
		&--next {
			right: -@nav-btn-width;
			text-align: left;
			&::after {
				content: "\f125";
			}
		}
		
		&--disabled, &--hidden {
			&:hover, &:focus {
				cursor: auto;
			}
			opacity: 0.25;
		}
		@media(max-width:@screen-md-min){
			display: none;
		}
		&:active, &:focus {
			outline: none !important;
		}
	}
	
	
	&.swiper--gallery {
		.swiper-container {
			.swiper-slide {
				width: auto;
			}
		}
		.swiper-button {
			&::after {
				top: 50%;
			}
		}
	}
	
}

.section--youmaywanto {
	@nav-btn-width: 3rem;
	.swiper-outer {
		.swiper-button {
			width: @nav-btn-width;
			&--prev {
				left: -2.25*@nav-btn-width;
			}
			&--next {
				right: -2.25*@nav-btn-width;
			}
		}
	}
}
