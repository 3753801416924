@coupon--large-breakpoint: 660px;
@coupon--large-max-width: 560px;

.coupon {
	display: flex;
	background-color: white;
	border: 1px dashed @brand-blue-dark;
	align-items: stretch;
	min-height: 140px;
	max-width: 380px;
	position: relative;
	.coupon-title {
		flex-basis: 160px;
		flex-grow: 0;
		flex-shrink: 0;
		display: flex;
		margin: 0;
		padding: 0;
		//background-color: coral;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		font-weight: 900;
		font-size: 24px;
		line-height: 1;
		color: @brand-blue-dark;
		strong {
			display: block;
			font-size: 50px;
			//color: @brand-blue-medium;
			color: @brand-pink;
			font-weight: 900;
		}
	}
	.coupon-text {
		display: flex;
		flex-basis: calc(100% - 160px);
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 10;
		line-height: 1.1;
		padding: 1.25rem 1rem 1rem 0;
		color:  @brand-blue-dark;
		.coupon-text-header {
			font-size: 18px;
			font-weight: 900;
			//text-transform: uppercase;
			margin: 0;
			padding: 0;
			width: 100%;
		}
		.coupon-text-body {
			font-size: 17px;
			margin: 0;
			margin-top: 0.5rem;
			padding: 0;
			width: 100%;
		}
		.coupon-links {
			width: 100%;
			margin-top: 0.5rem;
			@media(min-width:370px){
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
			.coupon-list-link {
				text-decoration: underline;
			}
		}
	}
	// hide bit of border under scissors
	&:before {
		position: absolute;
		left: 47px;
		bottom: -1px;
		height: 1px;
		width: 25px;
		background-color: white;
		content: "";
		z-index: 1;
	}
	// scissors
	&:after {
		position: absolute;
		left: 42px;
		bottom: -16px;
		width: 38px;
		height: 32px;
		z-index: 2;
		content: "";
		background-image: url(../images/icon-scissors.svg);
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.coupon.coupon--large {
	min-height: 310px;
	max-width: 100%;
	padding: 40px 0;
	height: 100%;
	position: relative;
	
	@media print {
		page-break-inside: avoid;
		break-inside: avoid;
		display: flex;
	}
	
	.coupon-header {
		flex-basis: 230px;
		min-width: 230px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		.coupon-title {
			display: block;
			font-size: 40px; //42px;
			color: @brand-blue-dark;
			strong {
				font-size: 72px;
				color: @brand-pink;
			}
		}
	
		.coupon-barcode {
			padding: 1rem;
			text-align: center;
			min-width: 38.61mm;
			img {
				margin: auto;
				display: block;
				// https://internationalbarcodes.com/ean-13-specifications/
				width: 31.35mm;
				height: 22.85mm;
			}
		}
	}
	

	
	.coupon-text {
		padding: 0;
		.coupon-text-header {
			font-size: 24px;
			padding-right: 40px;
		}
		.coupon-text-body {
			padding-right: 40px;
			//font-size: 20px;
			margin-bottom: 2rem;//1.25rem;
			margin-top: 1rem;
		}
		.coupon-text-price {
			padding-right: 40px;
			margin-bottom: 1.5rem;
			font-size: 42px;
			font-weight: 900;
			line-height: 1;
			.price-old {
				display: inline-block;
				margin-right: 2rem;
				text-decoration: line-through;
			}
			.price-new {
				color: @brand-pink;
			}
		}
		.coupon-text-info {
			padding-right: 40px;
			a {
				text-decoration: underline;
				color: inherit;
			}
			
			
			h3.coupon_validity_stores-title {
				font-size: 0.875rem;
				margin: 0;
				color: inherit;
				text-transform: none;
				font-weight: bold;
			}
			
			.coupon_validity_stores {
				margin-bottom: 2rem;
				font-size: 0.875rem;
			}
			p.coupon_validity_stores {
			}
			ul.coupon_validity_stores {
				//columns: 2;
				li {
					&:before {
						color: @brand-pink;
					}
				}
				
				@media (min-width:400px){
					// 2 sloupce
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					max-width: 400px;
					li {
						flex-basis: 50%;
						&:nth-child(2n+1){
							padding-right: 0.5rem;
						}
					}
				}
			}
			
			.coupon_validity_dates {
				margin-bottom: 2rem;
				font-weight: bold;
				strong {
					font-weight: bold;
					color: @brand-pink;
				}
			}
			
			.coupon-text-smallprint {
				font-size: 0.875rem;
				font-weight: bold;
			}
			
		}

	}
	
	.coupon-buttons {
		position: absolute;
		right: 20px;
		bottom: -25px;
		width: auto;
		.hidden-print;
		.btn {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			margin-left: 0.75rem;
			i {
				margin-right: 0.5rem;
			}
		}
	}
	
	&:before {
		left: 66px;
		width: 45px;
	}
	&:after {
		width: 54px;
		height: 48px;
		left: 61px;
		bottom: -24px;
	}
}

.coupon--narrow(){
	display: block;
	.coupon-text {
		padding: 40px 1.25rem 0 1.25rem;
	}
	.coupon-buttons {
		width: 100%;
		//padding: 0 1rem;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		.btn {
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			margin-left: 0.75rem;
			margin-right: 0.75rem;
		}
	}
	&:before {
		top: -1px;
		bottom: auto;
	}
	&:after {
		top: -24px;
		bottom: auto;
	}
}

body.body_coupons_index {
	.coupon.coupon--large {
		@media screen and (max-width:@coupon--large-breakpoint){
			.coupon--narrow();
		}
	}
}

body.body_coupons_detail {
	.coupon-wrap {
		max-width: 700px;
		margin: auto;
		margin-bottom: 4rem;
	}
	.coupon.coupon--large {
		@media screen and (max-width:@coupon--large-breakpoint){
			.coupon--narrow();
		}
		@media(min-width:800px){
			.coupon-header {
				//flex-basis: 300px;
			}
		}
	}
}

.coupon-wrap {
	position: relative;
	padding-bottom: 20px;
	border-width: 20px 20px 45px 20px;
	border-image-source: url(../images/border-coupon.svg);
	border-image-slice: 20 fill;
	border-image-outset: 20px;
	border-image-width: 20px;
	border-image-repeat: repeat;
	display: flex;
	flex-direction: column;
	//width: @coupon--large-max-width;
	//margin: auto;
	@media(min-width:(@coupon--large-breakpoint + 1px)){
		width: @coupon--large-max-width;
		min-width: @coupon--large-max-width;
	} 
}


.coupon.coupon--mini {
	display: block;
	padding: 1rem;
	min-height: 115px;
	max-width: 140px;
	margin-bottom: @grid-gutter-width/2;
	.coupon-title {
		font-size: 20px;
		strong {
			font-size: 36px;
		}
		margin-bottom: 0.5rem;
	}
	.coupon-text {
		display: block;
		padding: 0;
		text-align: center;
		a {
			text-decoration: underline;
		}
	}
	&:before {
		left: 16px;
	}
	&:after {
		width: 30px;
		height: 25px;
		bottom: -13px;
		left: 14px;
	}
}

.list-coupons--small {
	//margin-bottom: @grid-gutter-width/2;
	.list__item {
		flex-grow: 3;
		max-width: 100%;
		@media(min-width:@screen-md-min){
			flex-basis: 30%;
		}
		@media(min-width:@screen-sm-min)and(max-width:@screen-sm-max) {
			flex-basis: 45%;
		}
	}
	.coupon {
		margin-bottom: @grid-gutter-width/2;
		width: 100%;
		max-width: 100%;
		
	}
}

.list-coupons--big {
	margin-bottom: 4rem;
	.list__item {
		padding: 40px 30px;
		.coupon-wrap {
			//width: 100%;
			flex-basis: @coupon--large-max-width;
			flex-grow: 2;
			flex-shrink: 0;
			margin-left: auto;
			margin-right: auto;
		}
		@media(min-width:@screen-lg-min){
			padding: 40px 20px;
			&:nth-child(2n+1){
				padding-right: 40px;
			}
			&:nth-child(2n+2){
				padding-left: 40px;
			}
		}
		@media(max-width:@screen-sm-max){
			padding: 40px 10px;
		}
	}
	@media(max-width:1239px){
		display: block;
		.list__item {
			//width: 100%;
			margin: auto;
		}
	}
}

.body_coupons_index {
	.article__header {
		text-align: center;
		h1 {
			font-size: 3.125rem;
			text-transform: none;
			margin-bottom: 2rem;
		}
		.article__teaser {
			color: @brand-blue-dark;
			margin-bottom: 2rem;
		}
	}
}
