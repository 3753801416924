.io-picture {
	display: table;
	.space-stack-xl;
	&__link {

	}
	&__img {
		&:extend(.img-responsive);
	}
	&__caption {
		display:table-caption;
		caption-side:bottom;
		background: @gray-lighteer;
		.space-inset-base;
	}
	&__title {

	}
	&__desc {

	}
}