


ul.list--stores {
	margin: 0 0-@grid-gutter-width*0.825;
	li.list__item {
		margin: 0 @grid-gutter-width/2*0.75;
		margin-bottom: @grid-gutter-width/2;
		background-color: @gray-lightest;
		flex-grow: 0;
		flex-shrink: 0;
		
		position: relative;
		
		// pomocna promenna do css calc vzorce
		@m: @grid-gutter-width*0.75;
		flex-basis: ~"calc(33.333% - @{m})";
		min-height: 80px;
		
		line-height: 1.1;
		
		padding: 0;
		
		& > a {
			padding: @grid-gutter-width*0.4;
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			cursor: pointer;
			position: relative;
			
			color: inherit;
		
			.item-icon {
				color: @gray-light;
				font-size: 1.5rem;
				padding-right: @grid-gutter-width*0.4;
			}
		
			address {
				flex-grow: 100;
				margin: 0;
				margin-right: 1rem;
				.label {
					position: relative;
					top: -0.2em;
					margin-right: 0.25em;
				}
			}
		
			.item-info {
				margin-right: @grid-gutter-width/2;
				font-size: 12px;
				text-align: center;
				font-weight: bold;
				min-width: 114px;
				strong {
					font-size: 22px;
					color: @brand-primary;
				}
			}

			.item-button {
				min-width: 140px;
				display: none;
			}

			.item-arrow {
				width: 6px;
			}
		}
		
		&.has-sticker {
			& > a {
				min-height: 100px;
				padding-top: 45px;
				.item-arrow, .item-button, .item-info {
					//align-self: flex-end;
				}
				.item-flags {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					display: flex;
					justify-content: flex-end;
					padding: 0 28px;
					.store-sticker {
						margin-left: 8px;
					}
				}
			}
		}
		
		
		&.active, &:hover {
			background-color: white;
			box-shadow: 0 3px 8px 0px rgba(0,0,0,0.15);
			& > a {
				color: inherit;
				text-decoration: none;
				.item-info, .item-arrow {
					display: none;
				}
				.item-button {
					display: block;
				}
			}
		}
	}
	@media(max-width:@screen-md-max){
		li.list__item {
			// pomocna promenna do css calc vzorce
			@m: @grid-gutter-width*0.75;
			flex-basis: ~"calc(50% - @{m})";
			max-width: ~"calc(50% - @{m})";
		}
	}
	
	@media(max-width:@screen-xs-max){
		li.list__item {
			// pomocna promenna do css calc vzorce
			@m: @grid-gutter-width*0.75;
			flex-basis: 100%;
			max-width: 100%;
		}
	}
		
	@media(max-width:350px){
		margin-left: 0-@grid-gutter-width/2;
		margin-right: 0-@grid-gutter-width/2;
		display: block;
		li.list__item {
			margin-left: 0;
			margin-right: 0;
			& > a {
				flex-wrap: wrap;
				.item-info, .item-button {
					text-align: left;
					flex-basis: 80%;
					flex-grow: 10;
					padding-left: 27px;
				}
				.item-button {
					flex-basis: 100%;
					margin-top: 10px;
				}
			}
		}
	}
}

#stores-map {
	// Styles for map clusters
	.map-cluster {
		// Note that $clusterSize must correspond with javascript iconSize property in customClusterIcon() function!
		@clusterSize: 40px;
		width: @clusterSize;
		height: @clusterSize;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #e70000; //$mapClusterBg;
		background-clip: padding-box;
		border-radius: @clusterSize/2;
		font-size: 1rem;
		font-weight: bold;
		color: white;
	}

	.leaflet-popup {
		.leaflet-popup-content-wrapper {
			@cardHeight: 140px;
			padding: 0;
			border-radius: 0;
			width: 355px;
			min-height: @cardHeight;
			.leaflet-popup-content {
				margin: 0;
				.map-info-popup {
					
					display: flex; // 140 180 35
					padding: 0;
					width: 355px;
					min-height: @cardHeight;
					//border-bottom: 3px solid @brand-blue-dark;
					background-color: white;
					&__image {
						flex-basis: 140px;
						width: 140px;
						img {
							width: 140px;
							height: auto;
						}
					}
					&__body {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: stretch;
						padding: 0.5rem;
						flex-grow: 4;
						a.btn {
							color: white;
						}
						.store__title {
							font-weight: bold;
							margin-bottom: 0.25rem;
							margin-top: 0;
						}
						address {
							margin-bottom: 0.25rem;
						}
					}
					&__store-area {
						flex-basis: 35px;
						width: 35px;
						flex-shrink: 0;
						background-color: #1a547a;
						display: -webkit-box;
						display: flex;
						-webkit-box-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						align-items: center;
						overflow: hidden;
						color: #fff;
						font-size: 18px;
						font-weight: 700;
						> span {
							display: block;
							-webkit-transform-origin: center center;
							transform-origin: center center;
							-webkit-transform: rotate(-90deg);
							transform: rotate(-90deg);
							white-space: nowrap;
							padding-right: 1rem;
						}
					}
				}
			}
			@media( max-width: 375px ) {
				width: 300px;
				min-height: 100px;
				.leaflet-popup-content {
					.map-info-popup {
						width: 300px;
						min-height: 100px;
						&__image {
							flex-basis: 100px;
							background-color: #eee;
							width: 100px;
							img {
								width: 100px;
								height: auto;
							}
						}
						&__store-area {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
	.leaflet-popup-close-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 30px;
		font-size: 24px;
		line-height: 1;
		color: red;
		&:hover {
			color: white;
		}
	}
}