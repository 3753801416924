.article {
	// sirka u clanku v 'Návody & Inspirace' je kvuli dobrmu UX pri cteni max sirka 
	[data-controller="instructions_and_inspirations"] & {
		max-width: 40rem;
	}
	&__title {
		font-size: 3.125rem; //@font-size-h2;
		color: @text-color;
		font-weight: 100;
	}
	&__teaser {
		font-size: @font-size-large;
		padding-bottom: @space-xl;
		font-weight: @font-weight-bold;
	}
	&__content {
		strong {
			color: @brand-blue;
		}
		h1 {
			text-decoration: line-through;
			position: relative;
			&::after {
				content: "Nepoužívejte prosím nadpis H1 (#) v těle článku.";
				position: absolute;
				font-size: 1rem;
				background: @brand-danger;
				.space-inset-sm;
				right: 0;
				top: 0;
				opacity: 0.9;
				max-width: 10em;
				// transform: translate(-50%, -50%);
			}
		}
		h2 {
			font-weight: @font-weight-bold;
			color: @brand-blue;
			text-transform: none;
			font-size: @font-size-h3;
			margin-bottom: @space-lg;
		}
		h3,h4 {
			font-weight: @font-weight-semibold;
			text-transform: none;
			font-size: @font-size-base;
			margin-bottom: 0;
		}
		// za poslednim odstavcem udelej vetsi odsazeni
		p+h2 {
			margin-top: @space-xxl;
		}
		ul:not(.list) {
			&:extend(.ul--base all);
		}
		p+ul {
			margin-top: @space-base;
		}
		// vsechny obrazky resposivni
		img {
			.img-responsive;
		}

	}
}

body[data-controller="articles"] {
	.article {
		&__header {
		}
		&__title {
			font-size: 3.125rem;
			text-align: center;
			margin-bottom: 50px;
			text-transform: none;
			text-align: left;
		}
	}
	.section__title-alt {
		text-transform: none;
	}
}