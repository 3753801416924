.langswitch {
	font-weight: @font-weight-semibold;
	line-height: 1rem;
	.section--preheader & {
		text-align: right;
	}
	.offcanvas & {
		.space-inset-base;
	}
}
.langlist {
	&:extend(.list-unstyled all);
	font-size: @font-size-small;
	display: inline-block;
	text-transform: uppercase;
	margin: 0;
	&__item {
		line-height: 1rem;
		display: inline-block;
		padding: 0 @space-sm;
		&.active {
			color: #91bbcf;
			border-right: 1px solid #91bbcf;
		}
		&:not(:last-child):not(.active) {
			border-right: 1px solid #91bbcf;
		}
	}
}