/*.banner-shoproku {
	margin-bottom: 1.5rem;
	.banner-shoproku-1200 {
		display: none;
		@media(min-width:1001px){
			display: block;
		}
	}
	.banner-shoproku-970 {
		display: none;
		@media(min-width:@screen-md-min)and(max-width:1000px){
			display: block;
		}
	}
	.banner-shoproku-600 {
		display: none;
	}
	.banner-shoproku-400 {
		display: none;
	}
	.banner-shoproku-300 {
		display: none;
	}
	@media(max-width:@screen-sm-max){
		//margin-left: 0 - @grid-gutter-width/2;
		//margin-right: 0 - @grid-gutter-width/2;
		.banner-shoproku-600 {
			display: block;
			margin: auto;
			width: 100%;
			max-width: 580px;
		}
	}
	@media(min-width:331px)and(max-width:430px){
		.banner-shoproku-600 {
			display: none;
		}
		.banner-shoproku-400 {
			display: block;
			margin: auto;
			width: 100%;
			max-width: 580px;
		}
	}
	@media(max-width:330px){
		.banner-shoproku-600 {
			display: none;
		}
		.banner-shoproku-300 {
			display: block;
			margin: auto;
			width: 100%;
			max-width: 580px;
		}
	}
}*/


.banner-shoproku {
	@media(min-width:1291px){
		display: none;
	}
	margin-bottom: 1.5rem;
}



.shoproku-sticky-widget {
	position:absolute;
	top:260px;
	left:-41px;
	z-index:960;
	@media(max-width:1290px){
		display: none;
	}
}
.shoproku-sticky-widget:hover{
	transition:left 0.3s;
	left:0;
}