// 2cols

.layout--base-2 {
	@media (min-width: @screen-md-min) {
		display: flex;
		flex: 0 1 auto;
		flex-direction: row;
		flex-wrap: nowrap;
	}
	margin-left: @grid-gutter-width / -1.5;
	margin-right: @grid-gutter-width / -1.5;
}

.layout__col--content, .layout__col--aside {
	padding-left: @grid-gutter-width / 1.5;
	padding-right: @grid-gutter-width / 1.5;
}

.layout__col--content {
	flex-grow: 1;
	order: 2;
}

.layout__col--aside {
	flex-basis: 18rem;
	flex-shrink: 0;
	order: 1;
	//flex: 1 1 14rem !important;
}