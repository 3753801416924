.header-main {
	background: white;
	position: relative;
	z-index: @zindex-header;
}
.section--preheader {
	.compadv {
		&:extend(.list-unstyled all);
		display: flex;
		justify-content: center;
		font-weight: @font-weight-semibold;
		margin: 0 auto;
		grid-column: ~"2/3";
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		&__item {
			padding: 0 0.5rem;
			line-height: 1rem;
			&:not(:last-child) {
				border-right: 1px solid white;
			}
		}
		@media(max-width: @screen-sm-max){
			margin-bottom: 0;
			padding-bottom: 0.5rem;
		}
	}
	.langswitch {
		grid-column: ~"3/4";
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.preheader__left {
		font-family: pacifico,cursive;
		padding-left: 55px;
		font-size: 1.1rem;
		@media(max-width: @screen-sm-max){
			padding: 0;
			text-align: center;
		}
	}
}

.section--header {
	.logo {
		display: flex;
		align-items: center;
		position: relative;
		flex-basis: 27%;
		&--image {
			display: block;
			width: auto;
			height: 60px;
			img {
				height: 60px;
			}
		}
	}
}

.sectionheader__wrapper {
	display: flex;
	justify-content: space-between;
	height: 80px;
}

.header__claim {
	&:extend(.text--fancy);
	color: @brand-blue-medium;
	font-size: 2rem;
	align-self: center;
	margin: 0;
	text-align: center;
	flex-grow: 2;
	display: none;
	@media (min-width: @screen-sm-min) {
		display: block;
	}
	a {
		&, &:link, &:visited, &:hover, &:active {
			color: @brand-blue-medium;
			text-decoration: none;
		}
	}
}

.eshoplink {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	flex-basis: 27%;
	.btn {
		width: auto;
		padding-left: 4rem;
		padding-right: 4rem;
		.icon {
			margin-left: 1rem;
		}
	}
	@media(max-width:@screen-sm-max){
		.btn {
			padding-left: 2.5rem;
			padding-right: 2.5rem;
		}
	}
	@media(max-width:@screen-xs-max){
		flex-basis: 50%;	
	}
}

@media(max-width:900px)and(min-width:850px){
	.section--header {
		.logo {
			flex-basis: 30%;
		}
	}
	.eshoplink {
		flex-basis: 30%;
	}
}
@media(max-width:849px)and(min-width:768px){
	.header__claim {
		font-size: 1.55rem;
	}
	.section--header {
		.logo {
			flex-basis: 32%;
		}
	}
	.eshoplink {
		flex-basis: 32%;
	}
}
@media(max-width:767px){
	.section--header {
		.logo {
			flex-basis: 50%;
			flex-grow: 2;
		}
	}
	.eshoplink {
		flex-basis: 150px;
	}
}
@media(max-width:420px){
	.section--header {
		.logo {
			flex-shrink: 3;
			&--image {
				width: 100%;
				height: auto;
				img {
					width: auto;
					height: auto;
				}
			}
		}
	}
}

.userinfo {
	display: flex;
	.dropdown-menu {
		.dropdown-menu-right;
		margin: 0;
		border-top: @space-xs solid @brand-pink;
		top: calc( 100% ~"-" @space-xs);
		// TODO: mely by mit dropdowny pevnou sirku? Maj byt na mobilu vubec?
		max-width: calc( 100vw ~"-" @grid-gutter-width);
		@media (min-width: @screen-md-min) {
			min-width: 15rem;
		}
	}

}

.usermenu__item, .basketinfo__item {
	&.dropdown.open {
		.dropdown-toggle {
			background: @brand-pink;
			color: white;
			&::before {
				color: white;
			}
			&:hover {
				background: @brand-pink;
				color: white;
				~ .dropdown-menu {
					border-top: @space-xs solid @brand-pink;
				}
			}
		}
	}
}


.usermenu {
	&:extend(.list-unstyled all);
	font-size: @font-size-smaller;
	margin: 0;
	display: flex;
	.section--header & {
		display: none;
		@media (min-width: @screen-md-min) {
			display: flex;
		}
	}
}

.usermenu__item {
	display: flex;
	> a:not(:hover):not(:focus)::after {
		content: "|";
		position: absolute;
		left: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	> a {
		.space-inset-base;
		flex-grow: 2;
		display: flex;
		align-items: center;
		position: relative;
		text-decoration: none !important;
		&,
		&:link {
			color: @gray-base;
		}
		&:hover {
			background: @brand-pink;
			color: white;
		}
		&:active {
			background: @brand-pink;
			color: white;
		}
	}
	&--profile-link {
		display: flex;
		@media (min-width: @screen-md-min) {
			display: none;
		}
	}
	&--profile-dropdown {
		display: none;
		@media (min-width: @screen-md-min) {
			display: flex;
		}
	}
}


.section--navmain {
	.navbar {
		margin: 0 @grid-gutter-width/-2;
		@media (min-width: @screen-md-min) {
			margin: 0;
			display: flex;
		}
		display: block;
		flex-flow: row nowrap;
		position: relative;
		&__nav {
			// width: 100%;
			flex-grow: 1;
			@media (min-width: @screen-md-min) {
				flex-grow: 5;
				//max-width: 60%;
			}
		}
		&__search {
			flex-grow: 1;
			max-width: 40%;
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
			padding-left: @space-base;
			@media (max-width: @screen-sm-max) {
				max-width: 100%;
			}
		}
		&.navbar--device-sm {
			@media (min-width: @screen-md-min) {
				display: none;
			}
		}
		&.navbar--device-lg {
			display: none;
			@media (min-width: @screen-md-min) {
				display: flex;
			}
		}
	}
}

.navbar__search {
	// padding-left: @space-base;
}

.navbar__searchform {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	flex-grow: 0;
	background: @gray-lightest;
	transition: all 0.2s ease-in-out;
	border: 1px solid @gray-lightest;
	border-top: 1px solid @gray-lighter;
	border-bottom: 1px solid @gray-light;
	margin: -1px 0;
	&:focus-within {
		border: 1px solid @gray;
		background: white;
		flex-grow: 1;
		transition: all 0.6s ease-in-out;
	}

	.form-control {
		height: auto;
		background: none;
		border: 0;
		flex-grow: 1;
		box-shadow: none !important;
		// max-width: 10rem;
		// max-width: auto;
		text-align: right;
		// text-align: left;
		padding: @space-base @space-sm;
		transition: max-width 0.2s ease-in-out;
		// &:focus {
		// 	max-width: 100%;
		// 	transition: max-width 0.6s ease-in-out;
		// }
	}
	.btn {
		border: none;
		flex-grow: 0;
		border-radius: none;
		background: none !important;
		padding: 0 @space-base;
		font-size: @font-size-larger;
		color: @gray-base;
		> .icon {
			&::before {
				vertical-align: middle;
			}
		}
	}
}

