.editable {
	position: relative;
	
	.editable-edit-link {
		cursor: pointer;
		font-size: 1rem;
		line-height: 1;
		box-shadow: 1px 1px 2px rgba(0,0,0,0.6);
		display: block;
		opacity: 0.92;
		position: absolute;
		padding: 0.2rem 0.5rem;
		background-color: @brand-info;
		color: white;
		left: 0;
		top: -1.4rem;
		z-index: 10;
		&:hover {
			background-color: @brand-primary;
			color: white;
			text-decoration: none;
		}
		.editable-edit-link-text {
			display: none;
		}
	}
}
div.editable:hover {
	//box-shadow: inset 0px 0px 10px rgba(0,0,0,0.9);
	//border: 2px dashed @brand-info;
}
.editable:hover {
	/* Tento vnitrni stin neni dobry na spanech, ktere jsou pres vice radku */
	//border: 2px dashed @brand-info;
	// border davame na before element, aby nam tloustka borderu nehybala s layoutem
	&:before {
		position: absolute;
		display: block;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 2px dashed @brand-info;
	}
	.editable-edit-link {
		.editable-edit-link-text {
			display: inline;
		}
	}
}
