body.body_job_offers_index {
	.header-banner {
		background-color: @brand-blue-dark;
		border-bottom: 20px solid @gray-lighteer;
		@media(min-width:1150px){
			height: 625px;
			background-image: url(/public/dist/images/banner-kariera-24-lg.jpg);
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			img {
				display: none;
			}
		}
	}
	
	h1 {
		text-align: center;
		text-transform: none;
		margin-bottom: 50px;
	}
}

.list.list--jobs {
	margin-bottom: @grid-gutter-width*2;
	.list__item {
		display: flex;
		align-items: center;
		background-color: @gray-lightest;
		border-bottom: 1px solid @gray-light;
		margin: auto;
		max-width: 900px;
		margin-bottom: @grid-gutter-width/2;
		// padding: 1.25rem 2rem;
		padding: 0 2rem 0 1rem;
		color: inherit;
		text-decoration: none;
		.jobs-item-icon {
			font-size: 40px;
			color: @brand-blue--desat;
			margin-right: 2rem;
		}
		.jobs-item-image {
			width: 80px;
			height: 80px;
			margin-right: 2rem;
		}
		.jobs-item-title {
			flex-grow: 10;
			color: @brand-blue-dark;
			font-size: 20px;
			font-weight: bold;
			margin-right: 2rem;
			padding: 1.25rem 0;
		}
		.jobs-item-link {
			padding: 1.25rem 0;
			.icon {
				margin-left: 0.5rem;
			}
		}
		&.active, &:hover {
			background-color: white;
			box-shadow: 0 3px 8px 0px rgba(0,0,0,0.15);
		}
	}
	@media(max-width:@screen-xs-max){
		.list__item {
			flex-wrap: wrap;
			padding: 1rem;
			.jobs-item-icon {
				font-size: 24px;
				margin-right: 1rem;
			}
		}
		.jobs-item-title {
			flex-grow: 0;
			font-size: 18px;
		}
		.jobs-item-link {
			flex-grow: 10;
			flex-basis: 100%;
			min-width: 100%;
		}
	}
}


.body_job_offers_detail, .body_job_offers_index {
	.job-header {
		margin-bottom: 4rem;
		.lead {
			color: @brand-blue-dark;
		}
	}
}


.body_job_offers_detail {
	
	h1 {
		text-transform: none;
		text-align: center;
		margin-bottom: 2rem;
	}
	.lead {
		text-align: center;
		font-weight: bold;
		color: @brand-blue-dark;
		margin-bottom: 2rem;
	}
	
	.job-body {
		display: flex;
		align-items: flex-start;
		margin-bottom: 4rem;
		.job-body-text {
			//font-size: 1.25rem;
			flex-basis: 50%;
			flex-grow: 2;
			padding-right: 2rem;
			
			h2, .h2, h3, .h3 {
				font-weight: bold;
				font-size: 1.5rem;
				margin-bottom: 2rem;
				color: @brand-blue-dark;
				text-transform: none;
			}
			
			.job-description {
				margin-bottom: 2rem;
			}
			
			ul {
				.list--bullets;
			}
			
			.table {
				margin-bottom: 2rem;
				th {
					color: @brand-blue-dark;
					font-weight: bold;
				}
			}
			.btn {
				.hidden-print;
			}
			
		}
		.job-body-gallery {
			flex-basis: 50%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 -5px;
			figure {
				display: none;
				padding: 0;
				margin: 0;
				position: relative;
				cursor: pointer;
				a {
					display: block;
					margin: 0;
					padding: 0 5px;
				}
				img {
					display: block;
					width: 100%;
					height: auto;
				}
				&:nth-child(1){
					display: block;
					flex-basis: 100%;
					margin-bottom: 10px;
				}
				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					display: block;
					flex-basis: 33.33%;
					//flex-shrink: 1;
				}
				.overlay-morephotos {
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					left: 5px;
					top: 0;
					right: 5px;
					//width: calc(100% - 5px);
					height: 100%;
					background-color: fade( black, 33%);
					content: "+3";
					color: white;
					font-size: 2rem;
					font-weight: bold;
				}
			}
		}
		@media(max-width:@screen-sm-max){
			display: block;
		}
	}
	
	.section-reqoffer {
		margin-bottom: 4rem;
		h2, .h2, h3, .h3 {
			font-weight: bold;
			font-size: 1.5rem;
			margin-bottom: 0.5rem;
			color: @brand-blue-dark;
		}
		ul {
			.list--bullets;
		}
	}
	
}
