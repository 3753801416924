.alert-flash {
	position: absolute;
	transform: translate3d(-50%, -200%, 0);
	left: 50%;
	display: inline-block;
	top: @line-height-computed;
	line-height: @line-height-computed;
	z-index: @zindex-navbar-fixed;
	text-align: center;
	box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.2);
	animation-name: slideInDown;
	animation-duration: 0.5s;
	animation-delay: 0;
	animation-fill-mode: forwards;
	.close {
		margin-left: 0.5em;
		font-weight: @font-weight-light;
		line-height: @line-height-computed;
		position: relative;
		top: 2px;
	}
}

// Pokud neni alert "danger" zase hezky zajede, pokud je "danger" zustane viset do zavreni
.alert-flash:not(.alert-danger) {
	animation-name: slideInDown, slideOutUp;
	animation-duration: 0.5s, 0.5s;
	animation-delay: 0s, 5s;
	animation-fill-mode: forwards;
}

@keyframes slideInDown {
	from {
		transform: translate3d(-50%, -200%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(-50%, 0, 0);
	}
}

@keyframes slideOutUp {
	from {
		transform: translate3d(-50%, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(-50%, -200%, 0);
	}
}
