.sdetail {
	&__title {
		font-size: @font-size-h2;
		strong {
			color: @brand-blue;
		}
	}
	&__section {
		.space-stack-base;
		&--description {
			max-height: 10rem;
			overflow-y: hidden;
			text-overflow: ellipsis;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 10rem;
				background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				background-size: auto 4rem;
				background-repeat: repeat-x;
				background-position: left bottom;
				pointer-events: none;
			}
		}
	}
}

.stores-index-locator {
	#stores-map {
		height: 500px;
		margin-bottom: 1.5rem;
	}
	padding-bottom: 2.5rem;
}



article.store-detail {
	
	.section--content {
		padding-bottom: 0;
		margin-bottom: 8rem;
		position: relative;
		.store-stickers-wrapper {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			width: 100%;
			max-width: 1200px;
			display: flex;
			.store-sticker {
				margin-right: 12px;
			}
			/*.holiday-open-sticker {
				position: absolute;
				top: 0;
				left: 10%;
				@media(min-width:1000px){
					left: 5%;
				}
			}*/
		}
	}
	
	h1 {
		text-transform: none;
		text-align: center;
		strong {
			color:@brand-blue-medium;
		}
		.label {
			font-size: 0.35em;
			position: relative;
			top: -0.65em;
	
			@media(max-width: @screen-xs-max){
				font-size: 0.5em;
				top: -0.35em;
			}
			
		}
	}
	h2, .h2 {
		font-weight: bold;
		font-size: 1.5rem;
		margin-bottom: 1rem;
		text-transform: none;
	}
	h3, .h3 {
		font-size: 1.75rem;
		font-weight: bold;
		margin-bottom: 0;
		color: @brand-blue-medium;
	}
	p.store-is-open {
		font-size: 1.5rem;
		color: @brand-success;
		font-weight: bold;
		.hidden-print;
	}
	
	// grid
	.store-detail-section {
		display: flex;
		justify-content: space-between;
		.store-column-left {
			flex-basis: 100/12*5%;
			//border: 1px solid fade(orange,20%);
		}
		.store-column-center {
			flex-basis: 100/12*3%;
			//border: 1px solid fade(red,20%);
		}
		.store-column-right {
			flex-basis: 100/12*3%;
			//border: 1px solid fade(green,20%);
		}
		.store-column-centerright {
			flex-basis: 54%;
		}
		@media(max-width:@screen-md-max){
			.store-column-left {
				flex-basis: 40%;
			}
			.store-column-center {
				flex-basis: 25%;
			}
			.store-column-right {
				flex-basis: 25%;
			}
			.store-column-centerright {
				flex-basis: 55%;
			}
		}
		@media(max-width:@screen-sm-max){
			flex-wrap: wrap;
			.store-column-left {
				flex-basis: 50%;
				max-width: 50%;
				margin-right: 1rem;
				margin-bottom: 1rem;
			}
			.store-column-center {
				flex-basis: 40%;
				margin-bottom: 1rem;
			}
			.store-column-right {
				flex-basis: 100%;
				margin-bottom: 1rem;
			}
			.store-column-centerright {
				flex-basis: 100%;
			}
		}
		@media(max-width:@screen-xs-max){
			display: block;
			.store-column-left {
				max-width: 100%;
			}
			.store-column-right {
				display: block;
			}
		}
	}
	
	.header--store {
		position: relative;
		.store-detail-title {
			display: flex;
			justify-content: space-between;
			position: static;
			.store-pager {
				font-size: 3rem;
				color: @text-color;
				flex-basis: 50px;
				min-width: 50px;
				text-align: center;
				line-height: 60px;
				a {
					color: inherit;
					&:hover {
						color: @brand-blue-medium;
					}
				}
				@media(max-width:@screen-xs-max){
					flex-basis: 30px;
					min-width: 30px;
					font-size: 2rem;
					line-height: 2.5rem;
				}
			}
			
			img.store_open_hours_badge {
				transform: rotate(-15deg) translateY(-0.25rem);
				margin: 0 2rem;
				width: 105px;
				height: auto;
				float: right;
			}


			img.store_open_hours_badge-xs {
				position: absolute;
				right: 0; bottom: -35px;
					transform: rotate(-15deg) translateY(0rem);
					margin: 0 2rem;
					width: 95px;
					height: auto;
			}
		}
		
		.lead {
			text-align: center;
			@media(min-width: @screen-sm-min) {
				margin: 0 - @grid-gutter-width 0 @grid-gutter-width 0;
			}
			@media(max-width: @screen-xs-max) {
				margin: 0;
				padding: 0 0 @grid-gutter-width 0;
				font-size: 1.25rem;
			}
			
		}
		
	}
	
	.store-detail-info-wrap {
		border-bottom: 20px solid @gray-lighteer;
	}
	
	.store-detail-info {
		.store-image {
			position: relative;
			text-align: center;
			.store-image-link {
				display: inline-block;
				margin: 0.5rem 0;
			}
			.holiday-open-sticker {
				position: absolute;
				top: 0;
				right: 1.25rem;
			}
		}
		.store-hours {
			margin-bottom: 2rem;
			h2, p {
				margin: 0;
			}
			table {
				margin-top: 1rem;
				td, th {
					padding: 0;
					font-size: 1.5rem;
				}
				td {
					font-weight: bold;
					padding-left: 1rem;
				}
			}
			.special-opening-hours {
				color: inherit;
				del {
					color: @gray;
				}
			}
		}
		.store-promos {
			.store-promos-deals {
				margin-bottom: 2rem;
				font-size: 1.125rem;
				h3 {
					color: @brand-pink;
					a, a:hover {
						color: @brand-pink;
					}
				}
			}
			.store-coupons {
				display: flex;
				justify-content: flex-start;
				margin-bottom: 3rem;
				margin-left: -10px;
				margin-right: -10px;
				flex-wrap: wrap;
				.store-coupons-item {
					flex-basis: 50%;
					.coupon {
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}
		}
		@media(max-width:@screen-md-max){
			.store-hours, .store-promos {
				.store-coupons {
					justify-content: flex-start;
				}
			}
		}
		@media(max-width:@screen-sm-max){
			.store-image {
				text-align: left;
			}
			.store-promos {
				display: flex;
				justify-content: space-between;
				.store-promos-deals {	
					flex-basis: 50%;
					max-width: 50%;
					margin-right: 1rem
				}
				.store-promos-coupons {
					flex-basis: 40%;
				}
			}
		}
		@media(max-width:@screen-xs-max){
			.store-promos {
				display: block;
				.store-promos-deals {
					max-width: 100%;
				}
				.store-coupons {
					.store-coupons-item {
						flex-basis: 160px;
					}
				}
			}
		}
	}
	
	.store-detail-location {
		margin-top: 3rem;
		display: flex;
		.store-detail-map {
			height: 300px;
			.hidden-print;
		}
		.store-detail-contact {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.store-contact-table {
				table-layout: auto;
				tr {
					th {
						padding-right: 0.65rem;
						font-weight: bold;
						vertical-align: top;
					}
					td {
						font-weight: normal;
						vertical-align: top;
					}
				}
			}
		}
		.store-detail-manager {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.store-manager{
				text-align: center;
			}
		}
		.btn {
			margin-top: 2rem;
			.icon {
				margin-right: 0.25rem;
			}
		}
		@media(max-width:@screen-sm-max){
			flex-wrap: wrap;
			.store-detail-map {
				flex-basis: 100%;
				min-width: 100%;
			}
			.store-detail-contact {
				flex-basis: calc(50% - (2rem));
				max-width: calc(50% - (2rem));
			}
			.store-detail-manager {
				flex-basis: calc(50% - (2rem));
				max-width: calc(50% - (2rem));
			}
		}
		@media(max-width:@screen-sm-max){
			display: block;
			.store-detail-contact {
				max-width: 100%;
				margin-bottom: 2rem;
			}
			.store-detail-manager {
				max-width: 100%;
			}
		}
		.btn {
			.hidden-print;
		}
	}
	
	.store-detail-description {
		padding: 6rem 0;
		.row {
			display: flex;
			justify-content: space-between;
			.col {
				flex-basis: ~"calc(50% - 40px)";
				flex-grow: 0;
				flex-shrink: 0;
				padding: 0;
			}
			@media(max-width:@screen-sm-max){
				display: block;
			}
		}
		
		ul {
			.list--bullets;
		}
		
	}
	
	.store-detail-services {
		background-color: @gray-lightest;
		padding: 3rem 0;
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				text-align: center;
				flex-basis: 100%/6;
				padding: 1rem 0;
				font-size: 1.375rem;
				color: @brand-blue-dark;
				font-weight: bold;
				font-size: 1.375rem;
				.service-icon {
					font-size: 70px;
					margin: 0 1rem;
					color: @brand-blue-medium;
					i.pictogram {
						display: inline-block;
						width: 1em;
						height: 1em;
						&.wheelchair {
							background-image: url(../images/icon-wheelchair.svg);
							background-size: 70%;
							background-repeat: no-repeat;
							background-position: bottom center;
						}
						&.parking {
							background-image: url(../images/icon-parking.svg);
							background-size: 70%;
							background-repeat: no-repeat;
							background-position: bottom center;
						}
					}
				}
				.service-text {
					margin: 0 1rem;
				}
			}
		}
		@media(max-width:@screen-md-max){
			ul {
				li {
					font-size: 1.15rem;
					.service-icon {
						font-size: 85px;
					}
				}
			}
		}
		@media(max-width:@screen-sm-max){
			ul {
				justify-content: flex-start;
				li {
					flex-basis: 100%/4;
					font-size: 1.375rem;
					.service-icon {
						font-size: 100px;
					}
				}
			}
		}
		@media(max-width:@screen-xs-max){
			ul {
				li {
					flex-basis: 100%/3;
					font-size: 1.375rem;
					.service-icon {
						font-size: 100px;
					}
				}
			}
		}
		@media(max-width:550px){
			ul {
				li {
					flex-basis: 100%/2;
					font-size: 1.2rem;
					.service-icon {
						font-size: 100px;
					}
				}
			}
		}
	}
	
	.store-detail-gallery {
		padding-bottom: 1rem;
		&.section__surface--lace {
			&:before {
				display: none;	
			}
		}
	}
	
}
.store-sticker {
	display: block;
	width: 123px;
	height: 50px;
	background-image: url(../images/sticker-holiday-open-notext.png);
	background-size: contain;
	background-position: center top;
	background-repeat: no-repeat;
	&__text {
		display: block;
		font-size: 13px;
		text-transform: uppercase;
		margin: 5px;
		background-color: red;
		color: white;
		text-align: center;
	}
	&.holiday-open-sticker {
		background-image: url(../images/sticker-holiday-open-cs.png);
	}
	&.action-sticker {
		background-image: url(../images/sticker-store-akce.png);
	}
}
