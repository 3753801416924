// TODO: WHYUS list by mel byt list--base-5

.list--whyus {
	display: flex;
	margin: 0 -@grid-gutter-width/2;
	.list__item {
		padding: 0 @grid-gutter-width/2;
		flex: 1 1 20%;
	}
}

.card--whyus {
	font-size: @font-size-large;
	text-align: center;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;

	.card__image {
		// flex-grow: 1;
		margin-bottom: @space-lg;
		height: 8.75em;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
		}
		a, span {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.card__body {
		// flex-grow: 0;
	}

	.card__title {
		&:extend(.text-primary);
		font-size: 1.111111111em;
		text-transform: none;
		font-weight: @font-weight-bold;
		margin-bottom: @space-lg;
	}
	.card__teaser {
		line-height: 1.25;
	}

}

.card--whyus-heureka {
	.card__title {
		color: @brand-warning;
		// color: @brand-yellow;
	}
}

.body_stores_index {
	.section--whyus {
		//border-top: 20px solid @gray-lighteer;
		padding-top: 20px;
		.section__surface {
			//border: 1px solid red;
			box-shadow: none;
			&:before {
				display: none;
			}
		}
	}
}