h1 {
	@media(max-width:@screen-xs-max){
		font-size: 2rem;
	}
}


// Nadpis se stehem napric; text musi byt ve <span>
.heading-stitch {
	position: relative;
	background-color: @gray-lighteer;
	padding: 3.5rem 1rem 2.5rem 1rem;
	margin-bottom: 2rem !important;
	text-align: center;
	line-height: 1.1 !important;
	font-size: @font-size-h3 !important;
	color: @brand-blue-dark !important;
	font-weight: 900 !important;
	z-index: 1;
	& > span {
		display: inline-block;
		background-color: inherit;
		padding: 0 2rem;
	}
	// Steh 
	&:before {
		position: absolute;
		left: 0;
		top: 4.5rem;
		width: 100%;
		height: 2px;
		content: '';
		z-index: -1;
		// Pruhovane bg vytvori carkovanou caru
		background-image: linear-gradient(to right, transparent 60%, @brand-blue-dark 60%);
    background-size: 20px 100%;
	}
	// Trojuhelnik (sipecka) dole
	&:after {
		position: absolute;
		left: 50%;
		transform: translateX(-15px);
		bottom: 0;
		width: 0; 
		height: 0; 
		content: '';
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 15px solid white;
	}
}
