// Zamezuje nechtenemu vykresleni textu "~ Vše pro stylové šití ~" a "Látky" def. systemovym fontem
// a naslednemu probliknuti ve chvili, kdy je nahrany prislusny webfont.

html.js.loading-fonts {
	.header__claim, .quicksearch__title, .pfilter__title {
		opacity: 0;
	}
}

//	html.js.fonts-loaded {
//		.header__claim, .quicksearch__title, .pfilter__title {
//			opacity: 1;
//		}
//	}
