.btn {
	position: relative;
	white-space: normal;
	font-weight: @font-weight-bold;
	line-height: 1.5;
	// border: none;

	&, &:hover, &:focus, &:active {
		outline: 0 !important;
	}
	
}

.input-group-btn > .btn {
	border-radius: @border-radius-base;
}

.btn--ghost {
	border: none;
	background: transparent;
	.space-inset-base;
}

.btn-md {
	padding: 0.5rem 1.5rem;
}


@btn-dash-padding: 3px;

// buttons without stitch = .btn-default, .btn--arrow-l, .btn--arrow-r, .btn--arrow-d, .btn--social-fb

.btn:not(.btn-default):not(.btn--arrow-l):not(.btn--arrow-r):not(.btn--arrow-d):not(.btn--social-fb) {
	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		top: @btn-dash-padding;
		right:  @btn-dash-padding;
		bottom:  @btn-dash-padding;
		left:  @btn-dash-padding;
	
		// border dashed via border
		border-radius: @btn-border-radius-base;
		border-width: 1px;
		border-style: dashed;
		border-color: rgba(255, 255, 255, .85);
	
		// border dashed via SVG (no rounded corners)
		// background-image: url(../images/sq-dashed.svg);
		// background-size: 100% 100%;
		// background-repeat: no-repeat;
	}
}

.btn-default {
	font-weight: @font-weight-light;
	border-width: 1px;
	border-style: dashed;
	border-color: @brand-blue;
	color: @brand-blue;
	background-color: white;
	&:focus,
	&.focus {
		color: @brand-danger;
		border-color: @brand-danger;
		background-color: white;
	}
	&:hover {
		color: @brand-danger;
		border-color: @brand-danger;
		background-color: white;
		
	}
	&:active,
	&.active {
		color: @brand-pink--desat;
		border-color: @brand-pink--desat;
		background-color: white;
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
		color: gray;
		border-color: gray;
		background-color: transparent;
		}
	}
	&::before {
		content: none;
	}
}


// .btn-default {
// 	.btnstitched_maker(4px, #fff, @gray-light)
// }
// .btn-primary {
// 	.btnstitched_maker(4px, @brand-primary, #fff);
// }
// .btn-info {
// 	.btnstitched_maker(4px, @brand-info, #fff);
// }
// .btn-success {
// 	.btnstitched_maker(4px, @brand-success, #fff);
// }
// .btn-warning {
// 	.btnstitched_maker(4px, @brand-warning, #fff);
// }
// .btn-danger {
// 	.btnstitched_maker(4px, @brand-danger, #fff);
// }


.btn--arrow-l {
	position: relative;
	padding-left: 3.5em;
	text-align: left;
	&::before {
		content: "\f3d5";
		font-family: @font-family-icons;
		font-size: 2em;
		font-weight: @font-weight-light;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		width: 1em;
		text-align: center;
		position: absolute;
		left: @padding-base-horizontal / 2;
	}

}

.btn--arrow-r {
	position: relative;
	padding-right: 3.5em;
	text-align: left;
	&::after {
		content: "\f3d6";
		font-family: @font-family-icons;
		font-size: 2em;
		font-weight: @font-weight-light;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		min-width: 1em;
		text-align: center;
		position: absolute;
		right: @padding-base-horizontal / 2;
	}
}

.btn--arrow-d {
	position: relative;
	padding-right: 3.5em;
	text-align: left;
	&::after {
		content: "\f3d4";
		font-family: @font-family-icons;
		font-size: 2em;
		font-weight: @font-weight-light;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		min-width: 1em;
		text-align: right;
		position: absolute;
		right: @padding-base-horizontal / 2;
	}
}

.btn-link {
	// to act like button with same vertical height
	border: 1px solid transparent;
	&:extend(.link--underline all);
	// align arrow with other text
	&.btn--arrow-l {
		padding-left: 2.5em;
		&::before {
			min-width: 1em;
			left: 0;
		}

	}
	&.btn--arrow-r {
		padding-right: 2.5em;
		&::after {
			min-width: 1em;
			right: 0;
		}

	}
	// but not in button block
	&.btn--arrow-l.btn-block {
		padding-left: 3.5em;
		&::before {
			left: @padding-base-horizontal;
		}

	}
	&.btn--arrow-r.btn-block {
		padding-right: 3.5em;
		&::after {
			right: @padding-base-horizontal;
		}

	}
}


.btn-block {
//		& + & {
//			margin-top: -1px;
//		}
}
.btn--invert {
	&, &:link, &:visited, &:hover, &:focus, &:active {
		color: white;
	}
	&:hover, &:focus, &:active {
		border-color: white;
		&::before, &::after {
			color: white;
		}
	}
	.btn-link& {
		// to act like button with same vertical height
		border: 1px solid transparent;
	}
}
.btn--muted {
	border: 2px dashed @gray-light;
	background: none;
	color: @gray;
}


.btn-group-vertical {
	display: block;
	margin-bottom: @line-height-computed;
	ul& {
		&:extend(.list-unstyled);
		li {
			margin-top: -1px;
		}
	}
}

.btn--caret {
	position: relative;
	padding-right: 1em;
	text-align: left;
	&:after {
		content: "v";
		font-size: 1em;
		font-weight: @font-weight-black;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		min-width: 1em;
		text-align: right;
		position: absolute;
		right: 0;
	}
}

.btn--cta {
	&:extend(.btn-primary all);
	background-color: @brand-blue-medium;
	border-color: @brand-blue-medium;
	padding-left: 2 * @padding-base-horizontal;
	padding-right: 2 * @padding-base-horizontal;
}
.btn--cta-pink {
	&:extend(.btn-danger all);
	background-color: @brand-pink;
	border-color: @brand-pink;
	padding-left: 2 * @padding-base-horizontal;
	padding-right: 2 * @padding-base-horizontal;
}

.btn--social-fb {
	background: #004ba7;
	padding-left: 2 * @padding-base-horizontal;
	padding-right: 2 * @padding-base-horizontal;
	&, &:link, &:visited, &:hover, &:focus, &:active {
		color: white;
	}
	&:hover, &:focus, &:active {
		color: white;
		background: #002f68;
	}
	&::before {
		content: "\f231";
		font-family: @font-family-icons;
		font-size: 2em;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 2 * @padding-base-horizontal;
		text-align: center;
		font-weight: normal;
	}
}

.btn--ok {
	&:extend(.btn-danger all);
	padding-left: @space-md;
	padding-right: @space-md;
	&.btn-lg {
		padding: @padding-base-vertical (2 * @padding-base-horizontal);
	}
}

.btn--simple {
	border: 1px solid @gray-light;
	border-radius: 0;
	&, &:link, &:visited, &:hover, &:focus, &:active {
		color: inherit;
	}
	&:hover, &:focus, &:active {
		color: @gray-base;
		border-color: @gray-base;
	}
}