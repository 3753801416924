// We need to have the cookie consent banner under a modal dialog

@cookieConsentBaseZIndex: 990; // must be lower than modal z-index

.cookie_consent_banner_container {
  >div {
		z-index: @cookieConsentBaseZIndex; // must be lower than modal z-index
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		display: block;
		padding: @grid-gutter-width/2 0;
		background-color: fade(white, 97%);
		box-shadow: 0px 0px 16px 0 rgba(0,0,0,.250);
		@supports( backdrop-filter: blur(10px) ){
			background-color: fade(white, 90%);
			backdrop-filter: blur(10px);
		}

    .container-fluid {
      .container;
    }

		.btn {
			min-width: 120px;
		}

		.rejection-link {
			margin-bottom: 0;
		}
	}
	&::before {
		content: " ";
		display: block;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: fade(black, 15%);
		z-index: @cookieConsentBaseZIndex - 10;
		pointer-events: none;
	}

	@media print {
		display: none !important;
	}
}

// properties for cookie modal
#form_cookie_consents_edit {

	@customChkSize: 1.25em;

	.button-container {
		.btn {
			white-space: normal;
		}

		@media(min-width: @screen-sm-min) {
			display: flex;
			gap: 0.5em;
			.btn-primary {
				//order: 0;
			}
			.btn-secondary {
				//order: 1;
			}
		}

		@media(min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			.btn {
				margin-bottom: @grid-gutter-width/4 !important;
			}
		}
		@media (max-width: @screen-xs-max) {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			.btn {
				margin-bottom: @grid-gutter-width/4;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}


	fieldset:last-child, form {
		margin-bottom: 0;
	}

	label {

		font-weight: bold;
		position: relative;
		padding-left: @customChkSize * 1.5;
		display: flex;
		font-size: 18px;

		input[type="checkbox"] {
			
			//accent-color: @brand-success;
			position: absolute;
			left: 0;
			top: 0;
			// Hide the Native Checkbox Input
			appearance: none;
			width: 0;
			height: 0;
			// For iOS < 15 to remove gradient background
			background-color: transparent !important;
			// Not removed via appearance
			margin: 0;
			&::before, &::after {
				position: absolute;
				left: 0;
				top: 0;
				width: @customChkSize;
				height: @customChkSize;
				display: block;
				content: " ";
				cursor: pointer;
			}

			&::before {
				border: 1px solid currentColor;
			}

			&:checked {
				&::before {
					background-color: @brand-success;
				}
				&::after {
					background: 50% 50% no-repeat;
					background-size: @customChkSize*0.65;
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
				}
			}

			&:focus {
				&::before {
					outline: 3px solid fade(@brand-success,50%);
				}
			}

			&:disabled {
				opacity: 0.5;
			}
		}
	}

	&.align-controls-right {
		label {
			padding-right: @customChkSize * 1.5;
			padding-left: 0;
			input[type="checkbox"] {
				left: auto;
				right: @customChkSize;
			}
		}
		.button-container {
			@media(min-width:@screen-sm-min) {
				justify-content: flex-end;
				.btn-primary {
					//order: 1;
				}
				.btn-secondary {
					//order: 0;
				}
			}
		}
	}
	
}

// properties for both cookie banner and modal
.cookie_consent_banner_container, #form_cookie_consents_edit {
	.btn-secondary, .btn-outline-secondary {
		/*.btn-default;
		background-color: @gray-light !important;
		background-image: none;
		color: white;
		&:hover {
			background-color: @gray !important;
		}*/
		background-color: transparent;
		background-image: none;
		border-color: @gray-light;
		border-style: solid;
		border-width: 1px;
		color: @gray-light;
		&:hover {
			background-color: @gray-light;
			color: white;
		}
	}
	.btn-primary {
		background-image: linear-gradient(to bottom, @btn-default-bg 0%, darken(@btn-default-bg,5%) 100%);
		background-repeat: repeat-x;
			&:hover, &:active, &.active {
			background-color: darken(@btn-default-bg,5%);
			background-image: none;
		}
		color: @btn-default-color;
		border-color: @btn-default-bg;
		border-style: solid;
		border-width: 1px;
	}
}

// modal properties
#cookie_consent_modal_id {
	.modal-dialog {
		max-width: 1000px;

    .modal-body {
      padding: @grid-gutter-width/4 @grid-gutter-width/2;
    }
	}
}

.cookies-form-container {
	.container;
}

// chat schovat pod cookie listu 
iframe#supportBox-iframe {
	z-index: @cookieConsentBaseZIndex - 12 !important;
}