.table > tbody > tr {
	> th, > td {
		border: 0;
		padding: 0;
		padding-bottom: @space-base;
		padding-right: @space-sm;
	}
}

th {
	font-weight: @font-weight-regular;
	color: @gray-darker;
	font-size: 1em;
}
td {
	font-weight: @font-weight-semibold;
	// font-size-adjust: 0.55;
	color: @gray-base;
	font-size: 1em;
}

//table-products--sm napr v dropdownu v headeru

.table-products {
	width: 100%;
	// na malych zarizenich pres celou sirku
	margin: 0 @grid-gutter-width/-2;
	@media (min-width: @screen-md-min) {
		.space-stack-lg;
	}
	 > thead > tr {
		> th, > td {
			padding: @space-sm @space-base;
			font-size: @font-size-small;
			color: @gray-darker;
		}
	}
	// radka tabulky s bilym pozadim
	&__item {
		> th, > td {
			border-bottom: @space-xs solid @gray-lighteer;
			.table-products--sm & {
				border-bottom: 1px solid @gray-lighteer !important;
			}
			background: white;
			padding: @space-base;
		}
	}
	> tbody {
		> tr {
			&:last-of-type {
				> th, > td {
					border-bottom: @space-base solid @gray-lighteer;
				}
			}
		}
	}
	> tfoot {
		> tr:not(.table-products__item) {
			> th {
				font-weight: @font-weight-semibold;
				color: @gray-base;
			}
			> th, > td {
				padding: 0 @space-base;
			}
		}
		> .table-products__tfootstart {
			> th, > td {
				border-top: @space-xl solid @gray-lighteer;
				vertical-align: top;
			}
		}
	}
	&__img {
		// padding-right: 0 !important;
		width:1%;
		white-space: nowrap;
	}
	&__title {
		font-weight: @font-weight-bold;
		font-size: @font-size-large;
		.table-products--sm & {
			font-size: @font-size-base;
		}
		color: @gray-base;
		a {
			color: inherit;
			&:hover,
			&:focus {
				color: @brand-blue-dark;
			}
		}
	}
	&__id {
		text-align: center;
		font-weight: @font-weight-light;
		word-break: break-all;
	}
	&__amount {
		text-align: right;
		.form-control {
			display: inline-block;
			width: 5em;
		}
	}
	&__unit-price {
		text-align: right;
		font-weight: @font-weight-bold;
		&-before-sale,
		&-after-sale,
		&-sale {
			display: block;
		}
		&-before-sale,
		&-after-sale {
			line-height: 1.25;
		}
		&-before-sale {
			padding-top: 1.5em;
			color: @gray;
		}
		&-after-sale {
			color: @brand-red;
		}
		&-sale {
			line-height: 2;
			color: @brand-red;
			font-size: @font-size-small;
		}
	}
	&__price {
		text-align: right;
		font-size: @font-size-large;
		.table-products--sm & {
			font-size: @font-size-base;
		}
		font-weight: 700 !important;
	}
	&__item-actions {
		width: 1em;
		a {
			&:link,
			&:visited {
				color: @gray-dark;
			}
			&:hover,
			&:focus {
				color: @brand-blue-dark;
			}
			&:active {
				color: @brand-danger;
			}
		}
	}
	&__price-total {
		text-align: right;
		font-weight: 700 !important;
	}
	&__pricetopay {
		font-weight: 900 !important;
		vertical-align: baseline;
	}
	&__price-summary {
		// tabulka v tabulce
		> table {
			width: 100%;
			tr {
				> th, > td {
					color: @gray-base;
					line-height: @line-height-large;
				}
				> th {
					// padding: 0 @space-xxl 0 @space-base;
					font-weight: @font-weight-semibold;
				}
			}
		}
	}
	// pouziva se v paticce (txt styl stejny jako __title)
	h4 {
		font-weight: @font-weight-bold;
		font-size: @font-size-large;
		color: @gray-base;
		text-transform: none;
		margin-bottom: 1em;
	}
}

// resposivita tabulky v kosiku
@media (max-width: @screen-sm-max) {
	[data-controller="baskets"] .section--content,
	[data-controller="checkouts"] .section--content {
		h1 {
			display: none;
		}
		.table-products {
			width: auto;
			display: block;
			> thead {
				display: none;
			}
			> tbody > tr:last-of-type > td {
				border: none;
			}
			> tbody > tr {
				.clearfix; // protoze pouzivam float na TD
			}
			td:empty {
				display: none;
			}
			&__list,
			&__item,
			&__item > td {
				display: block;
				border: none;
				padding-top: @space-xs;
				padding-bottom: @space-xs;
			}
			&__item {
				position: relative;
				border: none;
				border-bottom: @space-xs solid @gray-lighteer;
				padding: @space-base 0;
				margin-bottom: @space-xs;
				background: white;
				&:hover, &:focus {
					> th, > td {
						background: white;
					}
				}
				&--sale {
						display: flex !important;
					> td {
						display: flex !important;
						align-items: center;
					}
					.table-products__title {

					}
					.table-products__price {
						justify-content: flex-end;
					}
				}
			}
			&__item-actions {
				border: 1px solid red;
				position: absolute;
				right: @space-base;
				top: @space-base;
				padding: 0;
				width: auto;
				font-size: @font-size-large;
				> a {
					padding: @space-sm;
					// color: @gray-base;
				}
			}
			&__img {
				width: auto;
				float: left;
				img {
					height: 5rem;
					width: auto;
				}
			}
			&__title {
				min-height: 3.5rem;
			}
			&__title,
			&__id {
				width: 100%;
				text-align: left;
			}
			&__unit-price {
				clear: both;
				display: flex !important;
				&-before-sale,
				&-after-sale,
				&-sale {
					padding: 0;
					margin-right: @space-xs;
					line-height: @line-height-base;
					font-size: @font-size-base;
				}
				&-sale {
					margin: 0;	
					margin-left: auto;
				}
			}
			&__amount {
				text-align: left;
				button:first-of-type {
					margin-left: 0;
				}
			}
			&__amount,
			&__price {
				float: left;
				width: 50%;
			}
			&__price {
				font-size: @font-size-larger;
			}
			// table footer
			> tfoot {
				display: block;
			}
			> tfoot .table-products__item {
				display: block;
			}
			&__tfootstart {
				display: flex !important;
				flex-flow: column nowrap;
				> td {
					display: block;
				}
				.table-products__addvoucher {
					order: 1;
				}
				.table-products__free-shipping {
					order: 3;
					h4 {
						display: inline-block;
						margin-right: @space-sm;
					}
				}
				.table-products__price-summary {
					order: 2;
				}
			}
		}
	}
}

.table-products__item--sale {
	> tr, td {
		&:first-child {
			text-align: center;
			&::before {
				content: "%";
				font-size: @font-size-h2;
				font-weight: @font-weight-bold;
				color: @brand-success;
			}
		}
	}
}

td.table-products__pricetopay {
	font-size: @font-size-larger;
	font-weight: 900 !important;
}

.basketinfo .table-products {
	table-layout: fixed;
	width: auto;
	.table-products__title {
		a:visited, a:link, a:hover, a:active {
			color: @gray-base;
		}
		@media (min-width: @screen-md-min) {
			min-width: 12em;
		}
	}
	> tfoot {
		> tr {
			&:first-child, &:last-child {
				> th, > td {
					padding-top: @space-xl;
				}
			}
			> th, > td {
				padding: @space-base;
				font-size: @font-size-large;
			}
		}
	}
}

th.text-success {
	color: @brand-success !important;
}
th.text--red {
	color: @brand-red !important;
}

// zarovnani tabulek v tabulce

th, td {
	&.text-right {
		> table {
			margin-left: auto;
		}
	}
	&.text-center {
		> table {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
