.body_searches_index {
	.section__header{
		h1 {
			text-align: center;
			font-size: 2.5rem;
			margin-bottom: 2rem;
		}
		margin-bottom: 4rem;
	}
	
	.form-inline {
		.btn {
			padding: 0.5rem 1.5rem;
			margin-left: 0.5rem;
			i {
				margin-right: 0.5em;
			}
		}
		label {
			margin-right: 0.5rem;
		}
		margin-bottom: 4rem;
	}
	
	.search-results-list {
		.list-unstyled;
		margin-bottom: 2rem;
		border-top: 1px dashed @gray;
		& > li {
			display: flex;
			justify-content: space-between;
			padding: 2rem 0;
			border-bottom: 1px dashed @gray;
			&:last-child {
				//border-bottom: none;
			}
			.search-result-text {
				//flex-basis: 75%;
				.search-result-title {
					font-size: 1.5rem;
					font-weight: bold;
					margin-bottom: 1rem;
					small {
						display: block;
						font-size: 0.875rem;
						color: inherit;
						margin-top: 0.5rem;
						&.is-open {
							color: @brand-success;
							font-weight: bold;
						}
					}
				}
				p.item-description {
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
			.search-result-links {
				//text-align: right;
				margin-top: 1rem;
				.btn {
					margin-bottom: 1rem;
					margin-right: 1rem;
					i {
						margin-left: 0.5rem;
					}
				}
			}
			.search-result-thumbnail {
				padding-left: 2rem;
			}
			
			&.search-results-list__item--dumlatek {
				display: block;
				.search-result-title {
					font-size: 1.5rem;
					font-weight: bold;
					margin-bottom: 1rem;
					color: @brand-pink--sat;
				}
				.search-result-content {
					justify-content: space-between;
					margin-bottom: 1rem;
					& > div {
						min-width: 0;
					}
					
					.search-result-content__text {
						margin-top: 3rem;
						p {
							margin-bottom: 1rem;
						}
						.btn {
							padding-left: 1.5rem;
							padding-right: 1.5rem;
						}
					}
					
					@media(min-width:@screen-md-min){
						display: flex;
						.search-result-content__text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							margin-top: 3rem;
							flex-basis: 250px;
							max-width: 250px;
							flex-shrink: 0;
							flex-grow: 1;
							padding-left: @grid-gutter-width*1;
							.btn {
								margin-bottom: 4rem;
								white-space: nowrap;
							}
						}
						.search-result-content__products {
							flex-grow: 10;
							max-width: 950px !important; //1200px - 250px;//calc(100% - 250px);
							& > div {
								position: relative;
							}
							.swiper-wrapper {
								max-width: 700px;
								width: auto;
							}
						}
					}
					
					/*@media(max-width:@screen-sm-max){
						display: block;
						
						.search-result-content__text {
							&:first-child {
								margin-top: 0;
								margin-bottom: 0.5rem;
							}
							&:last-child {
								margin-top: 0.5rem;
								margin-bottom: 0.5;
							}
						}
					}*/
					
				}
				
				.btn {
					i.icon {
						margin-left: .5rem;
					}
				}
			}
			
		}
	}
}