//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
	// schovat na malych zarizenich
	display: none;
	@media (min-width: @screen-sm-min) {
		display: block;
	}
	padding: 0.85rem 0;
	margin: 0;
	font-weight: @font-weight-light;
	list-style: none;
	align-self: center;
	> li {
		font-size: @font-size-smaller;
		
		display: inline-block;
		&:not(:last-of-type):after {
			content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 @space-sm;
			color: @gray-darker;
		}
	}
	a {
		font-size: @font-size-smaller;
		color: @gray-darker;
		text-decoration: underline;
		&:hover,
		&:focus {
			color: @brand-blue-dark;
		}
		&:active {
			color: @brand-danger;
		}
	}
	> .active {
		color: @gray-base;
		text-decoration: none;
		font-weight: @font-weight-regular;
	}
}

.link--goback {
	&:extend(.btn, .btn-link, .btn--arrow-l all);
	color: @gray-base !important;
	margin-left: auto;
	padding-right: 0;
	white-space: nowrap !important;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (min-width: @screen-sm-min) {
		max-width: 25%;
	}
	.hidden-print;
}
