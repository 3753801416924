
.actionbanner {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	line-height: 1;
	cursor: pointer;
	padding: 1em 1.5em;
	padding-right: 3.5em;
	margin-bottom: 1em;
	// padding nize> kvuli sipce..predelat mozna na flexbox..
	border: 1px solid @brand-blue;
	font-size: @font-size-larger;
	font-weight: @font-weight-light;
	color: @brand-blue;
	text-align: center;
	position: relative;
	&__name {
		&:extend(.text-uppercase);
	}
	&__text {
		// font-weight: @font-weight-bold;
	}
	&:hover {
		text-decoration: none;
		border-color: @btn-danger-bg-hover;
	}
	&:active {
		border-color: @btn-danger-bg-focus;
		color: @btn-danger-bg-focus;
	}
	&--danger {
		border: 1px solid @btn-danger-bg;
		color: @btn-danger-bg;
		&:hover {
			color: @btn-danger-bg-hover;
			text-decoration: none;
			border-color: @btn-danger-bg-hover;
		}
		&:active {
			border-color: @btn-danger-bg-focus;
			color: @btn-danger-bg-focus;
		}
		&::after {
			// color: @brand-danger;
		}
	}
	&--danger-inverse {
		line-height: 1.2;
		border: 1px solid @brand-pink--sat;
		background-color: @brand-pink--sat;
		color: white;
		&:hover, &:active, &:focus {
			color: white;
			border: 1px solid @brand-red;
			background-color: @brand-red;
			text-decoration: none;
		}
		&:not(a){
			cursor: default;
			padding-right: 1.5rem;
			&:after {
				display: none;
			}
		}
	}
	&--success-inverse {
		line-height: 1.2;
		border: 1px solid @brand-green;
		background-color: @brand-green;
		color: white;
		&:hover, &:active, &:focus {
			color: white;
			border: 1px solid @brand-green;
			background-color: @brand-green;
			text-decoration: none;
		}
		&:not(a){
			cursor: default;
			padding-right: 1.5rem;
			&:after {
				display: none;
			}
		}
	}
	&::after {
		content: "\f3d6";
		font-family: @font-family-icons;
		font-size: 1.5em;
		font-weight: @font-weight-light;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		min-width: 1em;
		text-align: right;
		position: absolute;
		right: @space-xl;
	}
	&--small {
		font-size: 1.375rem;
		padding: 1.25rem 1.5rem;
		padding-right: 2.5em;
		margin-bottom: @space-lg;
		&::after {
			font-size: 2rem;
			right: 0.625em; //20px
		}
	}
}

.bnr--social-n-newsletter {
	text-align: center;
	.bnr__title {
		font-weight: @font-weight-light;
		margin: @space-sm;
		strong {
			font-weight: @font-weight-bold;
			color: @brand-blue;
		}
	}
	.list {
		margin-bottom: @space-lg;
		display: flex;
		justify-content: space-around;
		&__item {
			// .space-inset-base;
		}
	}
	.form {
	}
}

// kazdej banner v sidebaru to ma takhle:
.layout__col--aside {
	.bnr {
		padding-top: @space-xxl;
		padding-bottom: @space-xxl;
	}
}

// bannery v dropdownu
.nav__panel-banners {
	.banner {
		.space-stack-lg;
		&__title {
			text-decoration: none;
			text-transform: none;
			font-weight: @font-weight-bold;
			font-size: @font-size-larger;
			.space-stack-base;
		}
		.btn {
			border-color: @brand-danger;
			color: @brand-danger;
			
		}
		&:hover,
		&:focus {
			color: @brand-blue;
			.btn {
				border-color: @brand-blue;
				color: @brand-blue;
			}
		}
		&__link {
			text-decoration: none !important;
		}
	}

}


.banner-wide {
	//margin-bottom: 1.5rem;
}