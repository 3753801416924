.list--contacts {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 6rem;
	flex-wrap: wrap;
	li.list__item {
		border-bottom: 1px dashed @gray-light;
		flex-basis: 50%;
		max-width: 50%;
		display: flex;
		padding: 40px;
		&:nth-child(2n+1){
			padding-left: 0;
			border-right: 1px dashed @gray-light;
		}
		&:nth-child(2n+2){
			padding-right: 0;
		}
		&:first-child, &:nth-child(2) {
			padding-top: 0;
		}
		// na poslednim radku bez border bottom, tj. vybrat posledni a predposledni item, pokud je lichy
		&:nth-last-child(-n+2):nth-child(odd), &:last-child {
			border-bottom: none;
			padding-bottom: 0;
		}
		.contact-thumbnail {
			flex-basis: 230px;
			padding-right: 30px;
			img {
				width: 200px;
				height: 200px;
			}
		}
		.contact-body {
			flex-grow: 2;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex-basis: calc(100% - 230px);
			.contact-text {
				font-size: 1.125rem;
				.contact-title {
					color: @brand-blue-dark;
					text-transform: none;
					font-size: 1.5rem;
					margin: 0;
					margin-bottom: 0.5rem;
					font-weight: bold;
				}
				address {
					margin-bottom: 0;
				}
			}
			.contact-buttons {
				.btn {
					margin-right: 1rem;
					margin-top: 0.5rem;
					padding: 0.5rem 0.75rem;
					font-size: 0.875rem;
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
	@media(max-width:@screen-md-max)and(min-width:1001px){
		li.list__item {
			padding: 20px;
			.contact-thumbnail {
				padding-right: 15px;
				img {
					width: 160px;
					height: 160px;
				}
			}
			.contact-body {
				.contact-title {
					font-size: 1.25rem;
				}
				.contact-text {
					font-size: 1rem;
				}
			}
		}
	}
	@media(max-width:1000px){
		display: block;
		li.list__item {
			max-width: 100%;
			padding: 40px 0;
			&:nth-child(2) {
				padding-top: 40px;
			}
			&:nth-child(2n+1){
				border-right: none;
			}
			&:nth-last-child(-n+2):nth-child(odd) {
				padding-bottom: 40px;
				border-bottom: 1px dashed @gray-light;
			}
		}
	}
	@media(max-width:540px){
		li.list__item {
			display: block;
			.contact-thumbnail {
				margin-bottom: 1rem;
				padding-right: 0;
				img {
					width: 100%;
					height: auto;
				}
			}
			.contact-body {
				.contact-text {
					margin-bottom: 1rem;
				}
			}
		}
	}
}


.body_contacts_index {
	h1 {
		text-align: center;
		text-transform: none;
		margin-bottom: 50px;//4rem;
	}
	.section--columns--widebg {
		h2 {
			margin-bottom: 2rem;
		}
	}
}