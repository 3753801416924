.cattitle {
	text-transform: none;
	font-size: @font-size-h3;
	font-weight: @font-weight-semibold;
	color: @gray-darker;
	.space-stack-lg;
}

.catteaser {
	font-size: @font-size-large;
	padding-bottom: @space-xl;
	margin-bottom: @space-lg;
	border-bottom: 1px solid @gray-light;
	> p:last-child {
		margin: 0;
	}
}
