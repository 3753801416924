.modal--mixer {
	.modal-dialog {
		width: 80vw;
		// height: 80vh;
		max-width: calc(@container-lg ~"-" 12rem);
	}
	.modal-content {
		background: @gray-lighter;
	}
	.modal-body {
		// padding: 0;
	}
}

.mixer {
	&__canvas {
		background: white;
		padding: @space-lg;
		border: 2px dashed @gray-light;
	}
	&__canvasinner {
		overflow:hidden;
		position: relative;
		min-height: 30rem;
	}
}

.card__wrapper--mixer {
	position: absolute !important;
}