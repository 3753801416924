// Mixins
// --------------------------------------------------

// Font Face
.font-face(@fontname, @fontfile, @fontweight:normal, @fontstyle:normal) {
	font-family: "@{fontname}";
	font-display: auto; /* or block, swap, fallback */
	src: url("@{fontfile}.woff2") format("woff2"), // Modern Browsers
		url("@{fontfile}.woff") format("woff"),
		url("@{fontfile}.ttf") format('truetype');
	font-weight: @fontweight;
	font-style: @fontstyle;
}


/* Font Face "Source Sans Pro"
 * 
 * @fontfamilyname          "Source Sans Pro"
 * @fontname-full           "Source Sans Pro Light Italic" ziskal jsem pri prevodu na  https://transfonter.org
 * @fontname-postscript     "SourceSansPro-LightItalic" ziskal jsem pri prevodu na  https://transfonter.org
 * @fontfile                cesta k souboru fontu
 * @fontweight              100, 200,.. normal, bold..
 * @fontstyle               italic, normal
 *
 * Note: Unless you're referencing one of the default system fonts,
 * it is rare for the user to have it locally installed, especially on mobile devices,
 * where it is effectively impossible to "install" additional fonts.
 * You should always start with a local() entry "just in case,"
 * and then provide a list of url() entries.
 * 
 * source: https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/webfont-optimization
*/

.font-face-ssp(@fontname-full, @fontname-postscript, @fontfile, @fontweight:normal, @fontstyle:normal) {
	font-family: "Source Sans Pro";
	font-display: auto; /* or block, swap, fallback */
	src: local("@{fontname-full}"),
		 local("@{fontname-postscript}"),
		 url("../fonts/source-sans-pro/@{fontfile}.otf.woff2") format("woff2"), // Modern Browsers
		 url("../fonts/source-sans-pro/@{fontfile}.otf.woff") format("woff"),
		 url("../fonts/source-sans-pro/@{fontfile}.ttf") format('truetype');
	font-weight: @fontweight;
	font-style: @fontstyle;
}
