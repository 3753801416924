article.article--wholesaling {
	.article__header {
		text-align: center;
		h1 {
			font-size: 3.125rem;
			text-transform: none;
			margin-bottom: 50px;
		}
		.article__teaser {
			color: @brand-blue-dark;
			margin-bottom: 2rem;
			font-size: 1.5rem;
		}
		.article__body {
			margin-bottom: 4rem;
			text-align: left;
		}
	}
	
	section.wholesaling__conditions {
		margin-bottom: 4rem;
		h2, .h2, h3, .h3 {
			font-weight: bold;
			font-size: 1.5rem;
			margin-bottom: 0.5rem;
			color: @brand-blue-dark;
		}
		.col {
			display: flex;
			div:first-child {
				padding-right: 2rem;
			}
			@media(max-width:@screen-sm-max)and(min-width:@screen-sm-min){
				display: block;
				div:first-child {
					padding-right: 0;
					padding-bottom: 1rem;
				}
			}
			@media(max-width:540px){
				display: block;
				div:first-child {
					padding-right: 0;
					padding-bottom: 1rem;
				}
			}
		}
		.wholesaling-icon {
			width: 130px;
			height: 130px;
		}
	}
	
	section.wholesaling__textconditions {
		margin-bottom: 4rem;
		margin-top: 6rem;
		h2, .h2, h3, .h3 {
			font-size: 1.85rem;font-weight: bold;
			font-size: 1.5rem;
			margin-bottom: 2rem;
			color: @brand-blue-dark;
		}
		.col {
			display: block;
			&:first-child {
				padding-right: 1rem;
				border-right: 0;
			}
			&:last-child {
				padding-left: 0.5rem;
			}
		}
	}
	
	section.wholesaling-text {
		margin-bottom: 4rem;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 100;
		.signature {
			font-size: 1.25rem;
			font-weight: bold;
		}
	}
	
	section.wholesaling__forms {
		margin-bottom: 4rem;
		.row {
			// align-items: flex-start;
		}
		h2, .h2, h3, .h3 {
			font-weight: bold;
			font-size: 1.5rem;
			margin-bottom: 1em;
			color: @brand-blue-dark;
		}
		p {
			margin-bottom: 1rem;
		}
		sup {
			color: @brand-blue-medium;
		}
		form {
			input {
				background-color: white;
			}
		}
	}

	ul {
		&:extend(.list--bullets all);
	}
	
	.section--wholesaling-helpline {
		background-color: @gray-lighteer;
		display: grid;
		grid-template-columns: 1fr 178px 1fr;
		margin-bottom: 4rem;
		align-items: center;
		&__text {
			grid-column: ~"1 / 2";
			grid-row: ~"1 / 1";
			padding: @grid-gutter-width;
			align-self: center;
			font-size: 1.25rem;
			color: @brand-blue-dark;
			font-weight: bold;
		}
		&__image {
			grid-column: ~"2 / 3";
			grid-row: ~"1 / 1";
			align-self: center;
			justify-self: center;
			text-align: center;
			img {
				margin: auto;
				width: 128px;
				height: auto;
				height: 128px;
				border-radius: 64px;
			}
		}
		&__contact {
			grid-column: ~"3 / 4";
			grid-row: ~"1 / 1";
			padding: @grid-gutter-width;
			align-self: center;
			h3 {
				font-size: 1.25rem;
				font-weight: bold;
				color: @brand-blue-dark;
				text-transform: none;
				margin-bottom: 0;
			}
			table {
				td, th {
					padding: 0;
				}
			}
		}
		@media(max-width:@screen-xs-max){
			display: block;
		}
	}
	
	.wholesaling--columns--headers {
		.row {
			.col {
				&:first-child {
					border-right: none;
				}
				h2, h3 {
					font-size: 1.5rem;
					margin-bottom: 2rem;
					font-weight: bold;
					color: @brand-blue-dark;
				}
			}
		}
	}
	
	.section--columns--widebg {
		margin-bottom: 20px;
		h2, h3 {
			font-size: 1.5rem;
			margin-bottom: 2rem;
			font-weight: bold;
			color: @brand-blue-dark;
		}
	}
}

