.js--font-test {
	margin-bottom: @space-lg;
}
.js--font-test .t {
	margin-bottom: 2px;
	line-height: 1;
}
.js--font-test .text {
	letter-spacing: -0.1em;
}
.js--font-test .text--i {
	color: red;
	font-style: italic;
}

// TrueType
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLight.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLightIt.ttf") format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Light.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-LightIt.ttf") format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-It.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Semibold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-SemiboldIt.ttf") format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-BoldIt.ttf") format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro TTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-BlackIt.ttf") format('truetype');
  font-weight: 900;
  font-style: italic;
}
.text--ttf {
	font-family: "Source Sans Pro TTF" !important;
}

// OpenType
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLight.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLightIt.otf") format('opentype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Light.otf") format('opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-LightIt.otf") format('opentype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Regular.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-It.otf") format('opentype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Semibold.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-SemiboldIt.otf") format('opentype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Bold.otf") format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-BoldIt.otf") format('opentype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-Black.otf") format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF";
  src: url("../fonts/source-sans-pro/SourceSansPro-BlackIt.otf") format('opentype');
  font-weight: 900;
  font-style: italic;
}
.text--otf {
	font-family: "Source Sans Pro OTF" !important;
}


// WOFF2 OpenType
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLight.otf.woff2") format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-ExtraLightIt.otf.woff2") format('woff2');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-Light.otf.woff2") format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-LightIt.otf.woff2") format('woff2');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-Regular.otf.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-It.otf.woff2") format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-Semibold.otf.woff2") format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-SemiboldIt.otf.woff2") format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-Bold.otf.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-BoldIt.otf.woff2") format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-Black.otf.woff2") format('woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro OTF WOF2";
  src: url("../fonts/source-sans-pro/SourceSansPro-BlackIt.otf.woff2") format('woff2');
  font-weight: 900;
  font-style: italic;
}
.text--otf-woff2 {
	font-family: "Source Sans Pro OTF WOF2" !important;
}
