#quicksearch_form option:first-child { background: #ddd }

.quicksearch {
	box-shadow: 0px 2px 8px 15px rgba(0, 0, 0, 0.20);
	box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.20);
	width: 270px;
	.body_main_index & {
		display: none;
		@media (min-width: @screen-md-min) {
		// zobraz pouze na uvodce
			display: block;
		}
	}
	&__title {
		//&:extend(.text--fancy);
	}
	&__form {
	}
	@media (min-width: @screen-md-min) {
		position: absolute;
		right: @grid-gutter-width;
		top: 50%;
		transform: translateY(-50%);
		
		overflow-y: visible;

		// padding-bottom: @space-sm; 

		.body_main_index & {
			padding: @space-sm;
			background-image: url(../images/bg_quicksearch.jpg);
			background-position: bottom center;
			background-repeat: no-repeat;
			background-color: @gray-lightest;
			z-index: 2;

			&:after {
				// fancy border
				content: "";
				position: absolute;
				pointer-events: none;
				/*top: -13px;
				right: -15px;
				bottom: -13px;
				left: -13px;*/
				top: -4px;
				right: -6px;
				bottom: -4px;
				left: -4px;
				border-color: #f2f1ed;
				border-style: solid;
				border-width: 15px 17px 15px 15px;
				border-image-slice: 15 17 13 15;
				border-image-slice: 15 17 15 15;
				// border-image-outset: 15px 17px 17px 15px;
				border-image-repeat: round round;
				//border-image-source: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAIRQTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+Pj2////xMTCxsbEx8fFw8PBxcXDx8bE////////////////xcTCOgQymQAAACx0Uk5TAELb90VW5w6x/v/7jhcqt33p/OFgAo30/aT2oMH5pf/6////////4l8Di/+DwuUWAAABQUlEQVR4nJWV2ZKCMBBFA0GWDopEMSrEERhw+///G5ICApWqMX1fuA+nqE6vhIzyfBpMfhOGm8kH1PfIWlFME2DpVvldxgBYtlN+mzJIaBwt2X3OYRA7HAtyEmflz+JEiuOBKc/zvWEvAkZdy0pOXlbldfLiMrE3CrMkN55L4+ltjEGAg4SOJMpdWIBcvTJO3OAkHvI7Bvzzj8awPeKDKww+oe4wJcHdFb4P7ZAauK6bVn9/O/3t+ro2cKrK/zCw/VMDP3TjPGe4seFmhp+6gq9Sfodl+dJwUfHvD+RVoeG3WzbeuteFGyzUNGSuec6GeVtUsLe53sCwIeECbm24XcDhCu5suFvByzA+NvxZhYF6IC51qKLgyo1qJEyLopofM1aogUWtAtSSQa0v1GLErVzUMsedCdwBwp023NEkbuf4D5/LinlYEoR6AAAAAElFTkSuQmCC');
				border-image-source: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAATlBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////FxcPDw8HHx8WsMB1QAAAAFnRSTlMA+PWi6efi28G3sY59X1ZFQioXDo2LY9veyQAAAQdJREFUOMuVlVcSgzAMRCWHXkxJEHD/i2aEY0yGmUS7PyzwftQpaix5iN7nuY9+4HKkbz0bFuF2Vj/V6utJ/dyqb55XtstExUVP9HJyyL2I+oIPn3WJfej/QDwqjp6r6/eTZTkV7P2FP3TnxCDXHbFlYlKmUTZiVEM0crD0Q4HgkUqxwlIS22Gmga2wtkOb4G1b9+tz3bctwa2Wv0gw3ZTgYlbbn/B6h9cT7mO5/8Gp3FRZAqyCXWzZWNRNzgY7nYbamueayEuC9zu3J1g85XY4V9iWZ4W9HfZIgFDqkKJA5cYaCWpRrPmhsYIGFloF0JKB1heyGKGViyxz5EwgBwg5bfjRhM7xG1VjRy1moDRvAAAAAElFTkSuQmCC');
				
				z-index: 1;
			}
		}

		&__titlewrapper {
			text-transform: none;
			margin: 0;
			display: flex;
			align-items: flex-end;
			padding: 0 @space-base @space-base;
		}
		&__title {
			//.space-inset-squish-sm;
			padding: 1rem 0.5rem 3.17rem 0.5rem;
			//font-size: 2.125rem;
			font-size: 1.625rem;
			font-weight: 100;
			text-transform: uppercase;
			color: @brand-blue-darker;
			text-align: center;
			line-height: 1;
			strong {
				display: block;
				font-weight: 900;
				//font-size: 2.8125rem;
				font-size: 2rem;
				color: @brand-blue;
				position: relative;
				& > span {
					position: relative;
					display: inline-block;
					background-color: @gray-lightest;
					padding: 0 0.35em;
				}
				&:before {
					position: absolute;
					top: 0.475em;
					left: 0;
					width: 100%;
					height: 2px;
					background-color: @brand-blue-darker;
					display: block;
					content: "";
				}
			}
		}
		&__subtitle {
			.space-inset-squish-sm;
			color: @gray-base;
			font-size: @font-size-smaller;
			line-height: 1.5;
		}
		
		&__top {
			display: block;
			background-color: white;
			margin: -6px -6px 0 -6px;
			padding: 20px 26px 15px 26px;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		
		&__bottom  {
			display: block;
			padding: @space-sm*1.1;
			padding-top: 0;
		}

		select.form-control {
			// background: @brand-green-light;
			height: auto;
			.space-stack-sm;
			&:last-of-type {
				.space-stack-base;
			}
		}
	}
	&:hover, &:hover * {
		text-decoration: none;
	}
	
	
	.btn {
		//margin-bottom: 0.35rem;
		i.icon {
			margin-left: 1rem;
		}
	}
}


.btn--quicksearch {
	background: @btn-danger-bg;
	color: white;
	border-radius: 0;
	display: block;
	&:hover,
	&:active,
	&:focus {
		background: @brand-pink--sat;
		color: white;
	}
	&::before {
		content: none !important;
	}
	&::after {
		content: "\f363";
		font-family: @font-family-icons;
		position: absolute;
		display: block;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: @space-xl;
		text-align: center;
	}
	@media (min-width: @screen-md-min) {
		display: none;
	}
	.offcanvas & {
		text-align: left;
	}
}
