// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
	margin-right: auto;
	margin-left: auto;
	padding-left:  @gutter / 2;
	padding-right: @gutter / 2;
	&:before, &:after {
		content: none;
	}
}

.container-fluid(@gutter: @grid-gutter-width) {
	margin-right: auto;
	margin-left: auto;
	padding-left:  @gutter / 2;
	padding-right: @gutter / 2;
	&:before, &:after {
		content: none;
	}
}