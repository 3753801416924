body[data-controller="pages"] {
	.article {
		&__header {
			text-align: center;
		}
		&__title {
			font-weight: 100;
			text-transform: none;
			margin-bottom: 50px;
			strong {
				font-weight: inherit;
			}
		}
		&__teaser {
			padding-bottom: 0;
			color: @brand-blue-dark;
		}
	}
	.section--content {
		margin-bottom: 0;
	}
}

.section--content {
	&.has-no-article-body.has-product-promotions {
		padding-bottom: 0;
		.article {
			&__title {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}


.section--patchwork-links{
	.list--patchwork-items {
		margin-bottom: 2rem;
		margin-top: 4rem;
		.pcard__link-wrapper {
			padding: 1.25rem;
		}
		.pcard__title {
			padding: 1.5rem 0 .5rem;
			.pcard__name {
				font-size: 1.25rem;
				font-weight: bold;
			}
		}
	}
}

body[data-page-code="notions"]{
	.product-promotions {
		margin-bottom: 0;
		min-height: 1rem;
	}
}

.section--patches-sale {
	font-size: 1.125rem;
	padding: 0rem 0 0 0;
	//background-image: url(../images/bg-lace-bottom.png);
	background-position: center top;
	background-repeat: repeat-x;
	background-color: @gray-lighteer;
	.row {
		display: flex;
		flex-wrap: wrap;
		.col {
			flex-basis: 50%;
			padding: 0 20px;
			&:nth-child(2n+1) {
				padding-left: 0;
			}
			&:nth-child(2n+2) {
				padding-right: 0;
			}
		}
	}
	.patches-sale-header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		h2, h3 {
			margin-top: 4rem;
			color: @brand-blue-dark;
		}
		.lead {
			font-size: inherit;
			font-weight: bold;
			color: @brand-blue-dark;
		}
	}
	.patches-sale-flower {
		padding: 0;
		img {
			position: relative;
			left: -20px;
			//top: -10px;
			//border: 1px solid red;
		}
	}
	@media(max-width:@screen-sm-max){
		padding-top: 6rem;
		.row {
			display: block;
			.col {
				padding: 0 0;
			}
		}
		.patches-sale-flower {
			img {
				left: 0;
				top: 0;
				margin: auto;
			}
		}
	}
}