
// Product Filter
.pfilter {
	//max-width: 80%;
	@media (min-width: @screen-md-min) {
		width: auto;
	}
	&__title {
		&:extend(.text--fancy, .text-primary);
		margin-bottom: @space-base;
		@media (min-width: @screen-md-min) {
			margin-bottom: 1.5em;
		}
	}
	.control-label {
		font-weight: @font-weight-semibold;
	}
}

.pfilter__fields {
	> .form-group {
		.space-stack-base;
		@media (min-width: @screen-md-min) {
			.space-stack-xl;
		}
		> .control-label {
			border-bottom: 1px solid @gray-light;
			display: block;
		}
		> .checkboxes {
			padding: 0;
			font-size: @font-size-small;
			max-height: 24rem;
			overflow-y: auto;
		}

		.radios label {
			font-size: @font-size-small;
			padding: 0;
		}
	}
}

.active-filters,
.pfilter {
	a[href^="#anchor--"] {
		@media (min-width: @screen-md-min) {
			display: none;
		}
	}
}

.active-filters {
	.space-stack-base;
}
